.navbar
  border-bottom: 1px solid $grey-300
  &.is-fixed-top
    z-index: 101
  .navbar-start
    align-items: center
  .navbar-item
    a
      color: $matik-black
    .help-nav-icon
      fill: $matik-black
    .UserAvatar--inner
      text-transform: uppercase

      img
        max-height: 100%

    .image
      width: 1rem
      height: 1rem
      margin-right: 0.5rem
      margin-top: 0.1rem
    .navbar-selected
      background-color: $matik-white
      border: 1px solid $grey-300
    .navbar-unselected
      color: $grey-700
    .button.is-primary:hover
      background-color: $matik-black !important
  .navbar-item.has-dropdown:hover, .navbar-item.has-dropdown.is-active
    .navbar-link
      background-color: $grey-300


  .navbar-container
    align-items: center
    &.has-text-unselected
      opacity: 0.6
    &:hover
      opacity: 0.8

  .navbar-avatar
    margin-right: 1rem
    .UserAvatar:hover
      opacity: 0.8

  .navbar-menu
    .navbar-end
      .navbar-white-hover
        color: white

  .matik-nav-brand
    margin-left: 1rem
    margin-right: .5rem
