.day-button
  text-align: center
  border-radius: 100%
  border-width: 0
  max-width: 35px
  width: 35px
  max-height: 35px
  height: 35px
  &.is-active
    background-color: $success
    color: white
