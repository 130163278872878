.data-source-empty-container
    .data-source-body
        position: absolute
        margin-top: 20px
        width: 100%

    .data-source-content-empty
        display: flex
        flex-direction: column
        gap: 1.75rem
        width: 100%
        max-width: $max-width
        margin-left: auto
        margin-right: auto
        padding: 12px
        padding-bottom: 64px

    .data-source-header
        font-weight: $font-weight-semi-bold
        font-size: $font-size-l

.empty-image-container
    display: inline-block
    position: relative
    svg
        position: absolute
        bottom: 43px
        right: 43px

.main.templates-list-empty .hero .hero-body
    align-items: start
    justify-content: center
    .subtitle
        margin-top: 0