div.access-select__control
  cursor: pointer
  min-height: 38px
  border: 0
  border: 1px solid $grey-300
  border-radius: 4px
  &:hover, &:active, &:focus, &.access-select__control--menu-is-open, &.access-select__control--is-focused
    border-color: $matik-green !important
    box-shadow: 0 0 0 1px rgba($matik-green, 0.25)
    cursor: default
  .access-select__indicator-separator
    background-color: $matik-white
    outline: 0
    box-shadow: none
  .access-select__dropdown-indicator
    display: none
  .access-select__placeholder
    font-size: 14px
  .access-select__multi-value
    background-color: $grey-100
    border: 1px solid $grey-300
    border-radius: 32px
    align-items: center
    padding: .1rem
    .access-select__multi-value__label
      font-size: 14px
      height: fit-content
      &.UserAvatar--inner
        font-size: 8px
    .access-select__multi-value__remove:hover
      background-color: transparent
  .access-select__option
    border-bottom: 1px solid $grey-300

div.matik-select__control
  cursor: pointer
  border-color: $grey-300
  &.top
    width: 7rem
    min-height: 42px
  &.matik-select__control--menu-is-open
    cursor: default
  &:hover, &:active, &:focus, &.matik-select__control--menu-is-open, &.matik-select__control--is-focused
    border-color: $matik-green !important
    box-shadow: 0 0 0 1px rgba($matik-green, 0.25)
  &:focus
    border: 1px solid $matik-green !important
    box-shadow: 0 0 0 1px rgba($matik-green, 0.25)
  .matik-select__indicator-separator
    background-color: $matik-white
    outline: 0
    box-shadow: none
  .matik-select__input input, .matik-select__value-container input
    height: 1rem
  .matik-select__placeholder
    color: $grey-600
  .matik-select__multi-value
    background-color: transparent
    border-radius: 0
    border: 0
    padding: 0 4px
    .matik-select__multi-value__label
      color: $matik-black
      font-size: inherit
    .matik-select__multi-value__remove
      color: $grey-500
      &:hover
        background-color: transparent
  .is-fully-selected
    .matik-select__multi-value
      background: $blue-100
  .matik-select__clear-indicator
    color: $grey-600

.select-error
  border-color: $danger !important
  &:hover, &:active, &:focus, &.matik-select__control--menu-is-open, &.matik-select__control--is-focused
    border-color: $danger !important
    box-shadow: 0 0 0 1px rgba($danger, 0.25)

.matik-refinement.add-extra-height .matik-select__value-container
  min-height: 60px

div.matik-select__menu
  z-index: 10
  color: $body-color
  cursor: pointer
  .matik-select__option
    cursor: pointer
  .matik-select__option--is-focused
    background-color: $grey-100 !important
  .matik-select__option--is-selected
    background-color: transparent
    color: $grey-700
    display: flex
    flex-direction: row
    justify-content: space-between
  &.matik-single-select__menu .matik-select__option--is-selected
    background-color: $blue-100
    color: $matik-black
.param-select-container
  .matik-select__single-value, .matik-select__option
    color: $yellow-500

div.home-select__control
  cursor: pointer
  border: 0
  background-color: $body-background-color
  width: 115px
  font-size: 14px
  text-align: end
  &.home-select__control--menu-is-open
    cursor: default
  &:hover, &:active, &:focus, &.home-select__control--menu-is-open, &.home-select__control--is-focused
    box-shadow: 0 0 0 0
  .home-select__indicator-separator
    visibility: hidden
  .home-select__input input, .home-select__value-container input
    height: 1rem
  .home-select__value-container .home-select__single-value
    color: $grey-700

div.recipient-select__control
  cursor: pointer
  min-height: 42px
  border-radius: 4px
  padding: 8px
  border: 1px solid $grey-300
  margin: 32px
  display: flex
  justify-content: center
  &:hover, &:active, &:focus, &.recipient-select__control--menu-is-open, &.recipient-select__control--is-focused
    border-color: $matik-green !important
    box-shadow: 0 0 0 1px rgba($matik-green, 0.25)
    cursor: default
  .recipient-select__indicator-separator
    background-color: $matik-white
    outline: 0
    box-shadow: none
  .recipient-select__dropdown-indicator
    display: none
  .recipient-select__placeholder
    font-size: 14px
  .recipient-select__multi-value
    background-color: $matik-white
    border: 1px solid $grey-300
    border-radius: 32px
    align-items: center
    padding: .1rem
    .recipient-select__multi-value__label
      font-size: 14px
      height: fit-content
      &.UserAvatar--inner
        font-size: 8px
    .recipient-select__multi-value__remove:hover
      background-color: transparent
  .recipient-select__option
    border-bottom: 1px solid $grey-300

div.home-select__dropdown-indicator
  color: $grey-700

div.home-select__menu
  z-index: 10
  color: $body-color
  cursor: pointer
  width: 125px
  .home-select__option
    cursor: pointer
  .home-select__option--is-focused
    background-color: $grey-100 !important
  .home-select__option--is-selected
    background-color: transparent
    .custom-selected
      color: $grey-700
      display: flex
      flex-direction: row
      justify-content: space-between
.param-select-container
  .home-select__single-value, .home-select__option
    color: $yellow-500

.access-modal-top
  display: flex
  justify-content: space-between

.access-add-button
  width: 7.5rem
  min-height: 42px

div.pagination-select__control
  cursor: pointer
  border: 0
  background-color: $body-background-color
  width: 115px
  &.pagination-select__control--menu-is-open
    cursor: default
  &:hover, &:active, &:focus, &.pagination-select__control--menu-is-open, &.pagination-select__control--is-focused
    box-shadow: 0 0 0 0
  .pagination-select__indicator-separator
    visibility: hidden
  .pagination-select__input input, .pagination-select__value-container input
    height: 1rem
  .pagination-select__dropdown-indicator
    color: $matik-black
    &:hover
      color: $grey-600
  .pagination-select__value-container .pagination-select__single-value
    color: $matik-black
