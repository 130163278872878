/* Overrides given checkbox stylings to match mocks */

[type="checkbox"]
  position: absolute
  left: -9999px
  vertical-align: middle

[type="checkbox"] + label
  position: relative
  padding-left: 1rem
  cursor: pointer

[type="checkbox"] + label:before
  content: ''
  position: absolute
  left: 0
  top: 2px
  width: 1rem
  height: 1rem
  border: 1px solid $grey-300
  box-shadow: none
  background: $matik-white
  border-radius: 4px

[type="checkbox"] + label:after
  position: absolute
  top: 2px
  left: 0
  font-size: 1.1rem
  width: 1rem
  height: 1rem
  line-height: 0.8
  background-color: $matik-green
  border-radius: 4px
  transition: all .2s
  opacity: 1
  transform: scale(1)

[type="checkbox"]:not(:checked):not(:indeterminate) + label:after
  opacity: 0
  transform: scale(0)

[type="checkbox"]:not(:indeterminate) + label:after
  content: url('../../images/checkmark_white.svg')

[type="checkbox"]:indeterminate + label:after
  content: url('../../images/checkmark_indeterminate.svg')

[type="checkbox"]:disabled + label:before
  background-color: $grey-200

[type="checkbox"]:disabled:checked + label:after
  background-color: $grey-200
  color: $grey-500

[type="checkbox"]:disabled + label
  color: $grey-500

[type="checkbox"]:focus + label:before
  border: 1px solid $matik-green


[type="checkbox"]:hover + label:hover:before
  border: 1px solid $matik-green

.ignore-disabled
  [type="checkbox"]:disabled + label:before
    background-color: $matik-white

  [type="checkbox"]:disabled:checked + label:after
    background-color: $matik-green
    color: initial

  [type="checkbox"]:disabled + label
    color: initial