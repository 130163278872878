@import '~bulma/sass/utilities/initial-variables.sass'
@import 'matik-palette'
@import 'matik-bulma-overrides'
@import 'matik-css-variables'

$body-color: $matik-black
$family-primary: RM Neue, sans-serif

$dropdown-item-color: $matik-black
$dropdown-item-hover-background-color: $grey-100

$navbar-background-color: $grey-100
$navbar-height: 3.5rem
$navbar-item-img-max-height: 1.5rem
$navbar-item-color: $matik-black
$navbar-item-active-background-color: $matik-black
$navbar-item-hover-background-color: $matik-black
$navbar-dropdown-item-hover-color: $matik-black

$body-background-color: $grey-100

$box-radius: $radius-small
$box-shadow: 0 5px 7px 0 rgba($matik-black, 0.3)

$button-padding-horizontal: 1rem
$button-border-color: $grey-300

$input-background-color: $white
$input-border-color: $grey-300
$input-placeholder-color: $grey-700
$input-focus-border-color: $matik-green
$input-focus-box-shadow-color: rgba($matik-green, 0.25)
$input-icon-active-color: $matik-green
$input-shadow: 0
$input-radius: 4px

$control-border-width: 1px
$control-padding-vertical: calc(0.5em - #{$control-border-width})

$label-weight: 400

$table-striped-row-even-background-color: $grey-100

$breadcrumb-item-separator-color: $grey-700

$message-body-border-width: 1px
$message-body-border-color: rgba($matik-black, 0.1) !important

$tabs-link-active-color: $body-color
$tabs-link-active-border-bottom-color: $matik-green
$tabs-link-hover-border-bottom-color: $matik-green

$size-1: 2.5rem
$size-2: 2.0rem
$size-3: 1.5rem
$size-4: 1.25rem
$size-5: 1.0rem
$size-6: 0.9rem
$size-7: 0.7rem

$rt-color-default: $matik-black
$rt-color-info: $matik-green
$rt-color-success: $green-500
$rt-color-warning: $matik-green
$rt-color-error: $red-500

$modal-card-head-background-color: $grey-100
$modal-card-head-padding: 10px 24px

@import '~bulma/sass/utilities/functions.sass'
@import '~bulma/sass/utilities/derived-variables.sass'
// Adds 'secondary' button and messages
$add-colors: ( "secondary": ($matik-white, $body-color), "message-success": ($green-700, $matik-black), "message-info": ($blue-100, $matik-black))

$colors: map-merge($colors, $add-colors)

@import '~bulma/sass/utilities/functions.sass'
@import '~bulma/sass/utilities/derived-variables.sass'
// Adds 'secondary' button
$add-colors: ( "secondary": ($matik-white, $body-color))

$colors: map-merge($colors, $add-colors)

@import '~bulma/bulma.sass'
@import '~react-toastify/scss/main'
@import '~react-dates/lib/css/_datepicker.css'
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
@import '~react-loading-skeleton/dist/skeleton.css'

@import 'index.sass'
@import 'navOverrides.sass'
@import 'components/auth/Auth.sass'

@import 'components/consumer/Create.sass'
@import 'components/consumer/ProductUpsell.sass'
@import 'components/shared/templates/TemplatesSidebar'

@import 'components/producer/templates/Templates.sass'
@import 'components/producer/templates/TabHeader.sass'
@import 'components/producer/dynamicContent/DynamicContent.sass'
@import 'components/producer/bulkAction/BulkActions'
@import 'components/producer/ItemTag/BulkItemTagModal.sass'
@import 'components/producer/dynamicContent/BulkUpdateDataSourceModal.sass'
@import 'components/producer/dynamicContent/layout'
@import 'components/producer/analytics/Analytics.sass'
@import 'components/producer/home/HomeTab.sass'
@import 'components/producer/onboarding/Onboarding.sass'
@import 'components/producer/nav/ProducerNav.sass'
@import 'components/producer/account/AccountInactive'
@import 'components/producer/dataSources/DataSources.sass'
@import 'components/producer/onboarding/AboutMe.sass'
@import 'components/producer/inputs/Inputs.sass'
@import 'src/components/shared/htmlBuilder/HtmlBuilder'

@import 'components/enterprise_settings/EnterpriseSettings.sass'

@import 'components/shared/Selector'
@import 'components/shared/ApiInputField.sass'
@import 'components/shared/Alerts.sass'
@import 'components/shared/Banner.sass'
@import 'components/shared/QueryLoading.sass'
@import 'components/shared/EditableField.sass'
@import 'components/shared/FormFromInputs.sass'
@import 'components/shared/PageLoader.sass'
@import 'components/shared/modal/Modalv2.sass'
@import 'components/shared/MultiAvatar.sass'
@import 'components/shared/FormSelect.sass'
@import 'components/shared/FullPageList.sass'
@import 'components/shared/DateRangePicker.sass'
@import 'components/shared/SortingArrows.sass'
@import 'components/shared/TemplateCard.sass'
@import 'components/shared/SortableEntityGrid.sass'
@import 'components/shared/search/Search.sass'
@import 'components/shared/SortDropdownSelector.sass'
@import 'components/shared/AsyncLoadImage.sass'
@import 'components/shared/flows/Flows.sass'
@import 'components/shared/DaySelector.sass'
@import 'components/shared/StackableSideModal.sass'
@import 'components/shared/Checkbox.sass'
@import 'components/shared/WYSIWYGEditor.sass'
@import 'components/shared/presentations/Presentations.sass'
@import 'components/shared/Preview.sass'
@import 'components/shared/spreadsheets/Spreadsheets.sass'
@import 'components/shared/RadioInput.sass'
@import 'components/shared/paramFields/BooleanInputField.sass'
@import 'components/shared/paramFields/DateInputField.sass'
@import 'components/shared/DisconnectedTemplateSlide.sass'
@import 'components/shared/EmptyStateBanner.sass'
@import 'components/shared/UserProfile.sass'
@import 'components/shared/sidepane/Sidepane.sass'
@import 'components/shared/notifications/NotificationCenter.sass'
@import 'components/shared/WithLoadingAndEmpty.sass'
@import 'components/shared/ProgressBar.sass'
@import 'components/shared/InputWithOptionalInputs.sass'

@import 'components/payments/Payments.sass'
@import 'components/producer/email/EmailTemplate.sass'
@import 'components/shared/ColorPicker.sass'
@import 'components/producer/templates/TemplateLibraryModal.sass'
@import 'src/components/shared/fileUpload/FileUpload'
@import 'src/components/shared/fileUpload/UploadedFileBanner'
@import 'src/components/shared/ButtonWithDropdown'

@import 'src/components/shared/templateBuilder/templateBuilder'
