.flows-sidebar
  margin-top: 56px
  padding: 1.5rem 2rem
  .sidebar-item
    display: flex
    align-items: center
    justify-content: space-between
    font-size: $font-size-m
    line-height: 24px
    margin-bottom: 6px
    color: unset
    padding: 6px 12px
    .count
      border-radius: 32px
      border: 1px solid $grey-300
      background: $matik-white
      padding: 0 8px

.flows
  font-size: $font-size-m
  line-height: 24px
  .control
    &.has-icons-left, &.has-icons-right
      .icon
        color: $grey-700
  .recurrence
    display: flex
    align-items: center
    color: $grey-600
    font-size: 12px
    line-height: 16px
    svg
      margin-right: 6px
  .search
    padding: 2rem 2.5rem
  .status-cell, .performance-cell, .template-cell
    display: flex
    justify-content: center
    align-items: center
    .template-type-icon-bg
      height: 24px
      width: 24px
      min-width: 24px
      svg
        height: 14px
  .status-indicator
    width: 10px
    height: 10px
    border-radius: $border-radius-full
    margin-right: 10px
  .green-status
    background-color: $matik-green
  .red-status
    background-color: $red-500
  .grey-status
    background-color: $grey-400
  .performance-icon
    margin-right: 10px
    &.error
      color: $red-500
    &.warning
      color: $yellow-500

.analytics-modal-body
  width: 100%
  height: 100%
  padding: 24px 32px 24px 32px
  margin: 32px 0px
  display: flex
  justify-content: center
  .analytics-modal-panel-container
    width: 70%
    display: flex
    flex-direction: column
    .analytics-panel
      width: 100%
      margin: 16px 0px
      .analytics-panel-title
        width: 100%
        font-size: 16px
        font-weight: 400
        line-height: 32px
        margin-bottom: 16px
        padding: 0 44px
        display: flex
        justify-content: space-between
      .analytics-panel-content
        height: 317px
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-template-rows: repeat(2, 1fr)
        grid-column-gap: 0px
        grid-row-gap: 16px
        justify-items: center
        .insight-card
          width: 300px
          height: 50%
          border: 1px solid $grey-300
          border-radius: 4px
          padding: 16px 16px 0px
          height: 100%
          grid-row-start: 1
          grid-row-end: 2
          &.sent
            height: 100%
            grid-row-start: 1
            grid-row-end: 3
          &.opens, &.clicks
            grid-row-start: 1
            grid-row-end: 2
          &.bounces, &.spam
            grid-row-start: 2
            grid-row-end: 3
          .insight-card-title
            font-weight: 600
            font-size: 12px
            line-height: 16px
          .insight-card-values
            display: flex
            flex-direction: column
            margin-top: 56px
            .insight-card-percent
              font-weight: 400
              font-size: 24px
              line-height: 16px
            .insight-card-total
              padding: 4px 2px
              font-weight: 400
              font-size: 14px
              line-height: 24px
              color: $grey-600
          .presentations-detail
            display: flex
            align-items: center
            line-height: 24px
            .indicator
              width: 8px
              height: 8px
              border-radius: $border-radius-full
            .count
              color: $grey-600
          .done, .sent
            background-color: $matik-green
          .warning
            background-color: $yellow-500
          .error, .unsent_error
            background-color: $red-500
          .remaining
            background-color: $grey-400
          .sent_override
            background-color: $green-600
          .unsent_condition, .failed_condition
            background-color: $blue-500

