.banner
  color: $matik-black
  padding: 16px
  width: 100%
  border-radius: $border-radius-small
  &.default
    background: $matik-white
    border: 1px solid $grey-300
  &.info
    background: $blue-100
    border: 1px solid $blue-200
  &.success
    background: $green-100
    border: 1px solid $green-200
  &.warning
    background: $yellow-100
    border: 1px solid $yellow-200
  &.error
    background: $red-100
    border: 1px solid $red-200
  img.icon
    width: 24px
    height: 24px
  .subline
    color: $grey-600
  .shrink
    flex-shrink: 1
  .centered-banner-text
    margin-left: 10px
    white-space: normal
  .banner-link
    text-decoration: underline
    cursor: pointer
  &.partial-width
    width: 80%
