.company-info-form
  label
    width: 160px
  label[data-tooltip-content]
    span
      border-bottom: 1px dashed $grey-400
.upgrade-pill
  border-radius: 32px
  padding: 8px
  height: 16px
  color: $matik-white
  background: linear-gradient(267deg, $matik-green 4.59%, #6A7BC7 100%)

.tb-tooltip-title
  color: $matik-white
  font-weight: 600
.tb-tootip-body
  color: $grey-400

.tb-branding-header
  padding: 24px
  width: 100%
  margin: 0px
  border-radius: 6px 6px 0px 0px
  border: 1px solid $grey-300

.tb-branding-form
  padding: 24px
  width: 100%
  margin: 0px
  margin-bottom: 40px
  border-radius: 0px 0px 8px 8px
  border-right: 1px solid $grey-300
  border-bottom: 1px solid $grey-300
  border-left: 1px solid $grey-300

.tb-branding-header-title
  color: $matik-black
  font-weight: 600
  font-size: 16px

.tb-branding-header-body
  color: $grey-600

.tb-desc-create
  color: $grey-600
.tb-desc-name
  text-decoration-line: underline
  cursor: pointer

.full-screen-modal
  .full-screen-modal-content
    .one-column-modal-body.template-builder-modal
      .modal-body-center
        gap: 0px

.template-builder-modal
  .template-type-select
    width: 576px

.full-screen-modal .full-screen-modal-content .one-column-modal-body .modal-body-center.template-builder-modal-loading
  align-items: center

.tb-template-description-box
  padding: 16px 20px 16px 24px
