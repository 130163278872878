.photo-wrapper
  position: relative

  .add-logo
    display: flex
    padding-top: 0.5rem

  &:hover
    .hover-close
      display: block

.hover-close
  background: rgba($matik-black, 0.5)
  color: $matik-white
  border-radius: 40px
  position: absolute
  top: -15px
  right: 25px
  padding: .2rem .5rem
  border: 1px solid $matik-white
  cursor: pointer
  display: none

.wrapper .enterprise-stats
  padding-right: 1rem
  .level-item
    margin-right: 2rem
    .heading
      color: $grey-700

.enterprise-settings-card
  .card-header
    border-bottom: 1px solid $grey-300
    .control
      margin-left: 0.75rem
      margin-right: -1rem
      float: right
  .team-text
    float: right

.fixed-update-button
  position: fixed
  bottom: 0
  right: 24px
  pointer-events: none
  z-index: 11
  &.full-width
    width: 100%
  .buttons
    display: flex
    justify-content: flex-end
    right: 0
    margin-bottom: 1.5rem
    .button
      pointer-events: all

.mail-settings-text
  margin: 16px 0
.mail-settings-divider
  height: 0
  border: 0.5px solid $grey-300
  margin: 16px 0
.add-email-modal-top
  display: flex
  width: 100%
  .add-email-input
    flex: 1
  button
    margin-left: 12px
.subuser-table-row
  height: 64px
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid $grey-300
  padding-left: 8px
  .subuser-delete
    cursor: pointer
.validate-button
  margin-right: 12px

.add-user-icon
  fill: $matik-black
  width: 20px
