.control
  .radio
    display: inline-flex
    align-items: center
    input[type='radio']
      appearance: none
      -webkit-appearance: none
      &::before
        content: ''
        display: inline-block
        height: 1rem
        width: 1rem
        border-radius: 50%
        border: 3px solid $matik-white
        background: $matik-white
        box-shadow: 0 0 0 1px $grey-300
        margin-top: calc(1.25rem - 50%)
      &:checked::before
        background: $matik-green
      &:disabled::before
        border-color: $grey-200
        background: $grey-200
      &:disabled:checked::before
        background: $grey-400
      &:hover:not(:disabled)::before,
      &:active:not(:disabled)::before,
      &:focus:not(:disabled)::before
        box-shadow: 0 0 0 1px $matik-green