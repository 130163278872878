.bulk-action-bar
  padding-left: 24px
  padding-right: 1.5rem
  padding-bottom: 1.0rem
  padding-top: 1rem
  position: sticky
  top: 56px
  z-index: 20
  min-height: 72px
  background: $grey-100
  &.presentations
    top: 0