div.columns .insights-column
  width: 375px
  border-left: 1px solid $grey-300

  .insight-box
    padding-bottom: .5rem
    padding-top: 0 !important
    padding-right: 1rem !important
    padding-left: 1rem !important

  .insights-border-bottom
    border-bottom: 1px solid $grey-300

  .insights-invite-button
    margin-top: 24px

  .user-insight-box
    height: 100px

    .user-avatar
      width: 50px

    .user-presentations-name
      color: $matik-black

    .user-presentations-count
      color: $grey-600

  .template-insight-box
    height: 150px

    .template-insight-thumbnail
      max-width: 130px

  .secondary-template-content
    display: flex
    justify-content: left
    align-content: center

    .secondary-template-text
      color: $grey-700
      margin-left: 6px
      margin-right: 8px

    .tertiary-template-text
      color: $grey-600

.text-grey
  color: $grey-700
