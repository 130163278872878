/* RM Neue Regular */
@font-face {
  font-family: RM Neue;
  src: url('./fonts/woff2/RM Neue Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/woff/RM Neue Regular.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* RM Neue Semibold */
@font-face {
  font-family: RM Neue;
  src: url('./fonts/woff2/RM Neue Semibold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/woff/RM Neue Semibold.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* RM Neue Bold */
@font-face {
  font-family: RM Neue;
  src: url('./fonts/woff2/RM Neue Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/woff/RM Neue Bold.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* RM Neue Regular Italic */
@font-face {
  font-family: RM Neue;
  src: url('./fonts/woff2/RM Neue Regular Italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/woff/RM Neue Regular Italic.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
