.analytics-dropdown
  width: 200px

.analytics-overview-cell
  @extend .pam
  justify-content: flex-start

.analytics-cartesian-grid
  stroke: $grey-300

.analytics-bar
  fill: $matik-green

.recharts-tooltip-cursor
  fill: $grey-100

.analytics-column-chart
  overflow-x: scroll
  width: 100%
  .csv-download
    float: right

.analytics-dropdown .matik-select__indicator.matik-select__loading-indicator
  display: none