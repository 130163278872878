.notification-center-status
  position: absolute
  top: 17px
  right: 16px
  width: 8px
  height: 8px
  z-index: 1
  border-radius: $border-radius-full
  background-color: $blue-500
  border: 1px solid $grey-100
.notification-center
  position: absolute
  top: 56px
  right: 0px
  width: 424px
  height: 561px
  cursor: auto
  box-shadow: $box-shadow-medium
  border-radius: $border-radius-small
  background-color: $matik-white
  line-height: 16px
  h1
    font-size: $font-size-m
    color: $grey-600
    padding: 18px 24px
    border-bottom: 1px solid $grey-200
  .notification-items
    display: flex
    flex-direction: column
    gap: 8px
    padding: 13px 8px
    height: 455px
    overflow-y: auto
    .item
      display: flex
      gap: 8px
      box-shadow: $box-shadow-none
      border-radius: $border-radius-small
      padding: 1rem
      &.unread-state
        background-color: $blue-100
      .icon-container
        display: flex
        width: 40px
        height: 40px
        justify-content: center
        align-items: center
        border-radius: $border-radius-full
        .icon
          width: 16px
        &.access
          background-color: $green-200
          .icon
            color: $green-700
        &.failure
          background-color: $red-200
          .icon
            color: $red-600
        &.warning
          background-color: $yellow-200
          .icon
            color: $yellow-500
      .unread-state
        width: 6px
        height: 6px
        margin-top: 8px
        border-radius: $border-radius-full
        background-color: $blue-500
      .read-state
        width: 6px
        height: 6px
        margin-top: 8px
        border-radius: $border-radius-full
        background-color: transparent
      .notification-details
        display: flex
        gap: 8px
        flex-direction: column
        max-width: 300px
        .notification-message
          font-size: $font-size-m
          line-height: 18px
          color: $grey-600
          h2
            font-weight: 600
            font-size: $font-size-m
            line-height: 18px
            color: $matik-black
            margin-bottom: 4px
        .notification-timestamp
          width: fit-content
          color: $grey-600
  footer
    font-size: $font-size-m
    color: $matik-black
    border-top: 1px solid $grey-200
    padding: 18px 24px
    cursor: pointer
