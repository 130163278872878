.sidepane-active
  position: absolute
  top: 3.5rem
  right: 0
  z-index: 98
  margin-top: 0
  width: 50%
  height: calc(100% - 3.5rem)
  &.with-drawer
    height: calc(100% - 3.5rem - 350px)
  &.is-full-screen-context
    top: 0
    z-index: 101
    height: 100%

.sidepane-loading
  width: 100%
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  flex-grow: 1

.input-form-sidepane,
.template-sidepane,
.template-with-sheet-sidepane
  display: flex
  flex-direction: column
  min-width: 650px
  max-width: 800px
  border-left: 1px solid $grey-300
  .dynamic-content-form
    max-height: calc(100vh - 7.5rem)
    overflow: scroll
    overflow-y: auto
    overflow-x: hidden
    .dc-form-main
      min-height: 0
  .input-form
    max-height: calc(100vh - 7.5rem)
    min-height: 0
    overflow: scroll
    overflow-y: auto
    overflow-x: hidden
  .tab-buttons
    border-left: 1px solid $grey-300
    border-top: 1px solid $grey-300
    height: 4rem
    width: calc(100% + 1px)
    right: 0
    background: $grey-100
    padding: 15px 25px 10px 0
  &.email-modal-sidepane
    height: 100vh
    margin-top: 0
    position: fixed

.presentation-log-sidepane
  display: flex
  flex-direction: column
  header
    padding: 1.5rem
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid $grey-300
    h1
      color: $matik-black
      line-height: 24px
      font-weight: 600
      font-size: $font-size-xl
      p
        color: $grey-600
        font-weight: 400
        font-size: $font-size-m
        line-height: 21px
  nav
    margin: 1.5rem 1.5rem 1rem 1.5rem
    border: 1px solid $grey-300
    border-radius: $border-radius-small
    .button
      border-top: none
      border-right: 1px solid $grey-300
      border-bottom: none
      border-left: none
      border-radius: 0
      padding: 1rem
      font-size: $font-size-m
      line-height: 24px
      &:first-child
        border-radius: 4px 0 0 4px
      &.is-active:after
        content: ""
        position: absolute
        height: 1px
        background: $matik-green
        top: 98%
        width: 75%
  .presentation-logs
    display: flex
    flex-direction: column
    gap: 8px
    padding: 0 1.5rem 1.5rem 1.5rem
    overflow-y: auto
    .presentation-log
      display: flex
      gap: 18px
      border: 1px solid $grey-300
      border-radius: $border-radius-small
      padding: 1rem
      color: $matik-black
      font-size: $font-size-m
      line-height: 18px
      .icon-wrapper
        display: flex
        min-width: 32px
        height: 32px
        justify-content: center
        align-items: center
        border-radius: $border-radius-full
        .icon
          width: 16px
        &.warning
          background-color: $yellow-200
          .icon
            color: $yellow-500
        &.error
          background-color: $red-200
          .icon
            color: $red-500
        &.info
          background-color: $blue-200
          .icon
            color: $blue-500
      .presentation-log-details
        flex-shrink: 1
        details
          color: $grey-600
          summary
            list-style: none
            cursor: pointer
            &:hover
              color: $matik-black
            &:after
              content: "More"
          &[open] summary::after
            content: "Less"
.checker-sidepane
  height: 100%
  display: flex
  flex-direction: column
  .sidepane-header
    &-inner
      display: flex
      flex-direction: row
      justify-content: space-between
      margin: 32px 24px
    .title
      margin: 0
      line-height: unset
    &-buttons
      display: flex
      flex-direction: row
      .button
        margin-left: 20px
        span
          display: flex
          height: 20px
        svg
          align-self: center
          height: 100%
  .footer
    display: flex
    margin-top: auto
    .button
      margin-left: 20px
  ul
    margin: 24px
  li
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 10px
    color: $grey-600
    .list-item-inner
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      margin: 20px 25px
      width: 100%
    .list-item-icon
      display: flex
      position: relative
      margin-right: 20px
      &-inner
        align-items: center
        display: inline-flex
        justify-content: center
        height: 30px
        width: 30px
      .check-icon
        position: absolute
        top: -5px
        right: -5px
        height: 12px
        width: 12px
    .list-item-button
      margin-left: auto

.flows-sidepane
  display: flex
  flex-direction: column
  line-height: 133%
  color: $matik-black
  z-index: 101
  h1
    padding: 2rem
    .icon-wrapper
      display: flex
      justify-content: center
      align-items: center
      border-radius: $border-radius-full
      height: 32px
      width: 32px
      background-color: $green-500
    .level-left
      flex-shrink: 1
      min-width: 0
    .flow-name
      display: flex
      flex-direction: column
      align-items: flex-start !important

      flex-shrink: 1
      font-size: $font-size-xl
      font-weight: 600
    .flow-recurrence
      display: flex
      align-items: center
    .dropdown-trigger
      .button
        height: 40px
        width: 40px
        padding: 0
        color: $grey-700
  section
    overflow-y: auto
  .run-status
    padding: 1rem 2rem
    border-bottom: solid 1px $grey-300
  .helper-text
    font-size: $font-size-s
    color: $grey-600
  .run-details
    padding: 1rem 2rem
    font-size: $font-size-m
    h2
      display: flex
      justify-content: space-between
      align-items: center
      padding-bottom: 6px
    button.icon-button
      cursor: pointer
      background-color: transparent
      border: 0
      color: $grey-600
    h3
      padding-bottom: 8px
    .presentations-detail-wrapper
      width: 450px
      display: flex
      justify-content: flex-start
      margin-bottom: 16px
    .presentations-detail
      display: flex
      align-items: center
      line-height: 24px
      .indicator
        width: 8px
        height: 8px
        border-radius: $border-radius-full
        &.done, &.sent
        background-color: $matik-green
        &.warning
          background-color: $yellow-500
        &.error, &.unsent_error
          background-color: $red-500
        &.remaining
          background-color: $grey-400
        &.sent_override
          background-color: $green-600
        &.unsent_condition, &.failed_condition
          background-color: $blue-500
      .count
        color: $grey-600
    .run-actions
      display: flex
      margin-top: 1rem
      .button, button
        margin-right: .75rem
.conditional-sidepane
  display: flex
  flex-direction: column
  width: 50%
  min-width: 650px
  max-width: 800px
  height: calc(100vh - 3.5rem - 1px)
  margin-top: 3.5rem
  position: absolute
  border-left: 1px solid $grey-300
  right: 0
  z-index: 100
  .dynamic-content-form
    max-height: calc(100vh - 7.5rem)
    overflow: scroll
    overflow-y: auto
    overflow-x: hidden
    .dc-form-main
      min-height: 0
  .tab-buttons
    border-left: 1px solid $grey-300
    border-top: 1px solid $grey-300
    height: 4rem
    width: calc(100% + 1px)
    right: 0
    background: $grey-100
    padding: 15px 25px 10px 0

.ssv-sidepane
  overflow: auto
  display: grid