.modal.v2
  background-color: rgba($matik-blue, 0.8)
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  justify-content: normal
  overflow: auto
  padding: 0 20px
  z-index: 101
  .modal-content
    display: flex
    flex-direction: column
    background: $matik-white
    transform: translateY(80px)
    max-width: 600px
    max-height: 650px
    border-radius: 8px
    box-shadow: 0px 3px 10px rgba($matik-blue, 0.08), 0px 14px 28px rgba($matik-blue, 0.08)
    margin: 0
    overflow: hidden
    .modal-card-head
      border-bottom: 1px solid $grey-300
      background: $matik-white
      padding: 24px
      .modal-card-title
        font-size: 1.1rem
        font-weight: 600
        color: $matik-black
        flex-shrink: 1
        line-height: 24px
      .button.is-close
        padding: 0
        width: 20px
        height: 20px
        border: none
    .modal-body
      padding: 24px
      overflow-y: auto
    .input-modal-body
      overflow-y: auto
    .modal-footer
      padding: 0 24px 24px 24px
      &.sticky
        overflow: hidden

.modal.v2.modal-iframe .modal-body
  padding: 0

.modal.v2.modal-ms-picker .modal-content
  max-width: 1080px
  max-height: 760px
  width: 100%
  height: 100%

.modal-dropdown.dropdown-menu
  display: block
  z-index: 101
  padding-top: 6px

.sub-template-modal-body
  .public-DraftStyleDefault-block
    margin: 0

.full-screen-modal
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  overflow: auto
  padding: 0
  z-index: 101
  .full-screen-modal-content
    margin: 0 auto
    height: 100%
    max-height: 100%
    max-width: 100%
    display: flex
    flex-direction: column
    .three-column-modal-body
      width: 100%
      padding: 24px 0
      display: flex
      justify-content: center
      overflow: auto
      flex: 1 1
      &:not(:last-child)
        margin-bottom: 80px
      .modal-body-center
        display: flex
        height: fit-content
        flex-direction: column
        align-items: flex-start
        padding: 32px 32px 80px 32px
        gap: 16px
        width: 776px
        background: $matik-white
        flex: none
        order: 0
        align-self: stretch
        flex-grow: 0
        .field
          width: 100%
          .label
            font-weight: 400
            font-size: 14px
            line-height: 24px
            margin-bottom: 0
          .form-info-text
            font-weight: 400
            font-size: 14px
            line-height: 24px
            color: $grey-600
          .input::placeholder
            color: $grey-600
          .input.subject-modal
            resize: none
          .react-autocomplete-input
            list-style: none
            margin: 0
            padding: 2px
            box-shadow: 2px 3px 5px rgb(0 0 0 / 20%)
            border-radius: 3px
            border: 1px solid silver
            background: $matik-white
            font-size: 90%
            max-height: 20em
            top: auto !important
            left: auto !important
            overflow-y: auto
            li.active
              background-color: $blue-500
              color: $matik-white

    .one-column-modal-body
      @extend .three-column-modal-body
      .modal-body-center
        width: 640px

    .modal-sidebar
      width: 275px
      &.invisible
        visibility: hidden
      li
        list-style-position: inside
  .button.is-close
    padding: 0
    background-color: $grey-100
    width: 20px
    height: 20px
    border: none
    margin-left: 28px
  .full-screen-modal-header
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 16px 48px
    border-bottom: 1px solid $grey-300
    p
      display: flex
      align-items: center
      cursor: pointer
    .full-screen-modal-title-box
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
    .full-screen-modal-title
      font-size: 18px
      line-height: 24px
      margin: 0
      font-weight: 600
    .full-screen-modal-subline
      font-size: 14px
      color: $grey-600
      line-height: 21px
.full-screen-modal-footer
  position: fixed
  background: $matik-white
  left: 0
  bottom: 0
  width: 100%
  padding: 20px 40px
  border-top: 1px solid $grey-300
  box-shadow: 0px 2px 8px 0px rgba(0, 31, 53, 8%)
  display: flex
  flex-direction: row
  justify-content: flex-end
  gap: 10px

.full-screen-modal-with-sheet
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: calc(100vh - 350px)
  overflow: auto
  padding: 2rem
  z-index: 101
  .full-screen-modal-header
    display: flex
    justify-content: space-between
    padding: 0 1.5rem