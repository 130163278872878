.welcome-onboarding-text-box
  max-width: 472px
  margin-left: auto
  margin-right: auto

.skip-onboarding-link
  color: $grey-600

.google-perms-alert
  .google-perms-popup
    background: linear-gradient($grey-100 243px, $grey-400 1px, #fff 244px)
  .google-perms-image
    border: 1px solid $grey-300
    box-sizing: border-box
    box-shadow: 0px 4px 10px 0px rgba($matik-black, 0.1)
    border-radius: 3px 3px 0 0