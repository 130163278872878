.matik-select__option
  padding: 8px 12px
.option-select-all
  border-bottom: solid 1px $grey-300
  margin: -8px -12px
  padding: 8px 12px
.tag.more-selected
  white-space: nowrap
  background-color: $matik-white
.select-menu-list-header
  padding: 0
  border-bottom: solid 1px $grey-300
  .icon.is-small
    color: $grey-700
  .select-menu-search
    border: none
    font-size: 14px
    &::placeholder
      color: $grey-600