@import '../../matik-palette'

.how-to
  height: fit-content
  background: $grey-50
  border: 1px solid $grey-300
  border-radius: 4px
  margin: 0 20px
  padding: 24px
  .how-to-heading
    color: $blue-500
    line-height: 16px
    font-weight: 700
    font-size: 12px
    margin-bottom: 10px
  .how-to-text
    font-size: 14px
    font-weight: 300
    line-height: 18px
    color: $grey-700
    margin: 10px 0px
    &.subheader
      font-weight: 600
      color: $matik-blue
    &.link
      color: $blue-500
      display: flex
      align-items: center
      svg
        fill: $blue-500
        width: auto
        height: 12px
        margin-right: 5px
    &.list
      display: list-item
      list-style-type: circle
      list-style-position: outside
      margin: 10px 10px
  .how-to-questions
    margin-top: 32px
