@import "matik-css-variables"
@import "matik-palette"

.icon-with-pill
  position: relative
  display: flex
  height: 2.5rem
  width: 2.5rem
  border: 1px solid $grey-300
  border-radius: $border-radius-full
  align-items: center
  justify-content: center
  color: $matik-black
  img 
    height: 1.25rem
    width: 1.25rem
  .icon-pill
    position: absolute
    bottom: -0.25rem
    right: -0.25rem
    display: flex
    height: 1rem  
    width: 1rem
    align-items: center
    justify-content: center
    border-radius: $border-radius-full
    color: $matik-white
    &.success
      background: $green-500
    &.error
      background: $red-500
    &.warn
      background: $yellow-500
