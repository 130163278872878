.modal.v2.template-library-modal
  z-index: 102
  .modal-body
    padding-top: 5px
  .template-library-modal-content
    .Tabs
      width: 100%
      height: 185px
      min-height: 185px
      padding: 2rem 1rem 0rem 0
      border-radius: 2rem

    .right-template-library-column
      height: 291px
      overflow-y: hidden
      padding-left: 0
      padding-right: 0

    .right-template-library-column-thumbnail-image
      width: 164px
      height: 97px
      text-align: center
      margin-bottom: 8px

    .image-wrapper
      max-width: 100%
      max-height: 100%
      width: auto
      height: auto

    .color-name
      font-size: 14px
      .description-text
        font-size: 12px !important
        color: $grey-600

    .template-library-description
      padding-left: 20px


    /* Tab Navigation */
    ul.nav
      width: 100%
      align-items: center

    ul.nav li
      padding: 1rem
      list-style: none
      cursor: pointer
      align-items: center
      display: flex

    ul.nav li.active
      background: #F3F9FE
      align-items: center

    .template-info-text-body
      width: 594px
      height: 84px

      font-family: 'RM Neue'
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 150%

      color: #3D4A60

      flex: none
      order: 0
      flex-grow: 1

    .large-template-library-thumbnail
      width: 492px
      height: 279px
      padding: 1px
      text-align: center


    .template-library-card-basic
       height: 279px

.template-library-list
  .template-card
    cursor: pointer
    .template-card-content
      border-top: none
    > img
      width: 100%

.template-source-dropdown
  display: flex
  flex-direction: row
  align-items: center
  gap: .6rem
  color: $matik-black
  overflow: visible

.template-source-dropdown img
  height: 20px
  width: 20px

.template-source-dropdown-select
  display: flex
  flex-direction: row
  align-items: center
  padding-left: 0.6rem

.template-source-dropdown-select img
  height: 20px
  width: 20px

.one-third-dropdown-box
  width: 30%

.template-builder-modal-dropdown-box
  padding-bottom: 0px
  padding-right: 0px
  width: 100%
  overflow: visible