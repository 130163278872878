.user-profile.box
  box-shadow: 0px 3px 6px rgba(0, 31, 53, 0.06)
  padding: 0
  .columns
    margin: 0
    padding-left: 8px
    &:not(:last-child)
      padding-bottom: 24px
    .column
      padding: 0
.user-avatar-action
  position: relative
  top: 10rem
  right: 3rem
  height: 32px
  width: 32px
  padding: 8px
  border-radius: 94px
.change-password
  padding: 0 24px 24px 24px
  .control
    width: 18rem
.notification-preferences
  padding: 0 24px 24px 24px