.day-picker-popup
  .matik-select__control
    border-radius: 4px 0px 0px 4px
  .info-text
    color: $grey-600
    font-size: 12px
    justify-content: center
    line-height: 140%
.day-date-caption
  display: flex
  flex-direction: row
  padding: 12px 16px
  gap: 8px
  background-color: $blue-100
  border-radius: 4px
  .caption
    inline-size: 375px
    overflow-wrap: break-word
    line-height: 140%
.help.caption
  inline-size: 300px
  color: $grey-600
  margin-top: .25rem