.no-inputs
  display: flex
  justify-content: center
  padding-bottom: 8rem

  .no-inputs-inner
    display: block

  .no-inputs-text
    font-weight: 600
    font-size: $font-size-xl
    line-height: 24px
    padding-top: 1rem
    padding-left: 28px

.generate-dropdown
  width: 14rem
  div
    cursor: pointer
.email-send-dropdown
  position: absolute
  right: 140px
  div
    cursor: pointer

.connect-to-google
  display: flex
  justify-content: center
  padding-bottom: 5rem

  .connect-to-google-inner
    display: block
    padding-left: 5rem
    padding-right: 5rem

    .image-container
      display: flex
      justify-content: center

  .connect-to-google-title
    font-weight: 600
    font-size: $font-size-xl
    line-height: 24px
    text-align: center
    padding-top: 1rem
    padding-bottom: 1rem

  .connect-to-google-text
    font-weight: normal
    font-size: $font-size-l
    line-height: 24px
    text-align: center
    color: $grey-500

  .connect-to-google-button
    display: flex

    button
      margin: 2rem auto 0 auto

.auth-token-error-icon,
.auth-token-success-icon
  position: absolute
  right: 12px
  bottom: 28px
  background-color: $matik-white
  border-radius: 10px

.consumer-section-with-sidebar
  padding: 2.5rem 0 0 0 !important
.consumer-template-main
  min-height: 0
  flex-grow: 1
  display: flex
  .create-sidebar
    border-right: 1px solid $grey-300
    height: 100%
    width: 480px
.end-user-template-nav
  border-bottom: 1px solid $grey-300
  background-color: $matik-white
  padding: 1rem 1.5rem
  margin-bottom: 0 !important
  width: 100%
  .level-left
    flex-shrink: 1
    min-width: 0
    .level-item:nth-child(2)
      flex-shrink: 1
      min-width: 0

.presentation-detail-wrapper
  display: flex
  flex-grow: 1
  min-height: 0
  .create-sidebar
    height: auto
    border-right: 1px solid $grey-300
    flex-direction: column
    .footer
      height: 73px
      padding: 1.10rem 1.5rem 3rem
      background-color: $grey-100
      border-top: 1px solid $grey-300
      .sidebar-regenerate-button
        width: 100%
    .presentation-input-form
      height: 100%
      padding-bottom: 0
      .field
        width: unset
  .container
    display: flex
    flex-direction: column
    align-items: center
.presentation-detail-back-button
  background: $matik-white
  box-shadow: none
.presentation-viewer-container
  border-radius: 6px
  overflow: hidden
.DateRangePicker, .date-input-field
  width: 100%

.complete-container
  .preview-subtitle
    margin-bottom: 20px
  #template-preview
    height: 500px !important
    margin-bottom: 0.5rem
    &.email
      border: 1px solid $grey-300
      border-radius: 0 0 4px 4px
      border-top: none

#template-preview
  border-radius: 4px
  &.email
    border: 1px solid $grey-300

.presentation-status-cell
  display: flex
  align-items: center
  .indicator
    width: 8px
    height: 8px
    border-radius: $border-radius-full
    &.done
      background-color: $matik-green
    &.warning
      background-color: $yellow-500
    &.error, &.unsent_error
      background-color: $red-500
    &.failed-condition
      background-color: $blue-500
