.email-template-body
  background: $matik-white
  padding-bottom: 24px
  flex: 1 1
  height: 100%
  overflow-y: auto
.email-form-container
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-end
  padding: 32px 32px 0px
  .email-form-field
    width: 100%
    height: 64px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    padding: 8px 4px
    border-bottom: 1px solid $grey-300
    margin: 0
    .email-form-label
      display: flex
      justify-content: flex-start
      width: 100px
      img
        width: 12px
        height: 12px
      p
        color: $grey-600
        font-weight: 400
        font-size: 14px
        line-height: 24px
        padding: 0px 30px 0px 8px
    .email-send-field
      display: flex
      justify-content: flex-start
      .email-form-input
        border: none
        outline: none
        font-weight: 400
        font-size: 14px
        line-height: 24px
        color: $matik-black
        width: 100%
        span
          color: $grey-600
      .recipient-input-field
        border: none
        outline: none
        font-weight: 400
        font-size: 14px
        line-height: 24px
        color: $matik-black
        width: 100%
        display: flex
        justify-content: flex-start
        .recipient-pill
          display: flex
          align-items: center
          justify-content: center
          width: fit-content
          height: 24px
          border: 1px solid $grey-300
          border-radius: 32px
          padding: 3px 8px 3px 8px
          margin: 0 10px 0 0
          &.dynamic
            padding: 3px 16px 3px 16px
        &::placeholder
          color: $grey-600

.email-html-container
  width: 100%
  padding: 32px 32px 0px
.add-attachment
  width: 100%
  display: flex
  flex-direction: column
  margin: 12px 0
.button.is-full-width
  width: 100%
  height: 48px
.add-attachment-img
  width: 12px
  margin-right: 10px

.html-editor-container
  border: 1px solid $grey-300
  border-radius: 4px

.html-preview-container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  border: 1px solid $grey-300
  border-radius: 4px

  .html-preview-dimensions
    width: 100%
    padding: 1rem 1rem 0 1rem
    display: flex
    justify-content: space-between

  .html-preview-view
    border-top: 1px solid $grey-300
    background-color: $grey-100
    overflow-y: hidden
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    div
      overflow-x: scroll
      .html-iframe.mobile
        width: 1000px
        height: 2000px
        border: 0
        zoom: 0.65
        -moz-transform: scale(0.65)
        -moz-transform-origin: 15% 0
        -o-transform: scale(0.65)
        -o-transform-origin: 15% 0
        -webkit-transform: scale(0.65)
        -webkit-transform-origin: 15% 0
@media screen and (-webkit-min-device-pixel-ratio: 0)
  .html-iframe.mobile
    zoom: 1

.no-dynamic-content-text
    color: $grey-500
    font-size: 14px
    line-height: 21px
.no-dynamic-content-title
  font-size: 18px
  font-weight: 600
.no-dynamic-content-body
  padding: 1rem 1rem 2rem

.email-from-modal-form
  display: flex
  height: fit-content
  flex-direction: column
  align-items: flex-start
  padding: 32px
  gap: 16px
  background: $matik-white
  flex: none
  order: 0
  align-self: stretch
  flex-grow: 0
  border: 1px solid $grey-300
  border-radius: 4px
  .field
    width: 100%
    .label
      font-weight: 400
      font-size: 14px
      line-height: 24px
      margin-bottom: 0
    .form-info-text
      font-weight: 400
      font-size: 14px
      line-height: 24px
      color: $grey-600
    .input::placeholder
      color: $grey-600
    .input.subject-modal
      resize: none
    .react-autocomplete-input
      position: absolute
      z-index: 10
      overflow: hidden
      list-style: none
      margin: 0
      padding: 2px
      box-shadow: 2px 3px 5px rgb(0 0 0 / 20%)
      border-radius: 3px
      border: 1px solid silver
      background: white
      font-size: 90%
      max-height: 20em
      overflow-y: auto
      box-sizing: border-box
      li.active
        background-color: $blue-500
        color: $matik-white

.email-recipients-modal-box
  padding: 0px
  gap: 16px
  width: 100%
  background: $matik-white
  flex: none
  order: 0
  align-self: stretch
  flex-grow: 0
  border: 1px solid $grey-300
  border-radius: 4px
  border-collapse: unset
  margin-bottom: 24px
  position: relative
  .help.is-danger
    position: absolute
    border: none
    bottom: -10px
  p
    width: 100%
    font-weight: 600
    font-size: 14px
    line-height: 24px
    padding: 16px 32px
    border-bottom: 1px solid $grey-300
  .email-recipients-dropdown
    width: 100%
    height: fit-content
    table
      width: 100%
      table-layout: fixed
    .email-recipients-dc-row
      display: flex
      justify-content: space-around
      align-items: center
      width: 100%
      height: 64px
      border-bottom: 1px solid $grey-300
      &:hover
        background: $blue-100
        cursor: pointer
      .dc-row-cell
        display: flex
        align-items: center
        &.icon
          min-width: 60px
          max-width: 60px
          padding: 10px
        &.method
          min-width: 60px
          max-width: 60px
        &.source, &.name
          min-width: 200px
          max-width: 200px
  .recipients-modal-empty-title
    margin: 0 0
  .recipients-modal-subtitle
    color: $grey-500
    border: none
.email-recipient-dropdown-box
  position: absolute
  padding: 0px
  grid-gap: 16px
  gap: 16px
  background: #FFFFFF
  order: 0
  align-self: stretch
  flex-grow: 0
  border: 1px solid $grey-300
  border-radius: 4px
  border-collapse: unset
  margin: 4px 0 24px 0
  box-shadow: 0px 3px 6px rgba(0, 31, 53, 0.06)
  z-index: 1
  .email-recipient-dropdown-body
    position: relative
    max-height: 336px
    display: block
    overflow-x: scroll
  .filters-container
    display: block
    td
      display: block
  .add-new-dc-row
    display: flex
    justify-content: flex-start
    align-items: center
    width: calc(100% - 1px)
    height: 64px
    padding: 16px 24px
    position: sticky
    bottom: 0
    background: $matik-white
    border-right: 1px solid $grey-300
    border-radius: 0 0 4px 4px
    &:hover
      background: $blue-100
      cursor: pointer
    img
      width: 16px
      height: 16px
      margin-right: 12px
.email-tab
  color: $matik-black
  font-size: 14px
  font-weight: 400
  width: 84px
  height: 48px
  padding: 0 12px
  display: flex
  align-items: center
  border: 1px solid $grey-300
  background: $matik-white
  span
    padding: 12px 0
  &.setup
    border-radius: 4px 0px 0px 4px
  &.send
    border-radius: 0px 4px 4px 0px
    border-left: none
  &.is-active, &.selected
    border: 1px solid $grey-300
    background: $grey-100
    box-sizing: border-box
    span
      border-bottom: 2px solid $matik-green
  &:hover
    border: 1px solid $grey-400
.email-attachments-list
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start
  div
    display: flex
    align-items: center
  span
    height: 24px
    height: fit-content
    display: flex
    justify-content: space-around
    align-items: center
    margin: 10px 10px
    p
      line-height: 24px

.email-preview-tab
  color: $matik-black
  font-size: 14px
  font-weight: 400
  width: fit-content
  height: 48px
  padding: 12px
  display: flex
  align-items: center
  border: 1px solid transparent
  background: none
  margin: 24px 10px
  &.is-active
    border: 1px solid $grey-300
    background: $matik-white
    border-radius: 4px
    box-sizing: border-box
  .email-preview-tab-icon
    fill: $matik-white
    stroke: $matik-black
    stroke-width: .5px
    margin-right: 5px
.email-preview-header
  display: flex
  flex-direction: column
  align-items: flex-start
  border: 1px solid $grey-300
  border-radius: 4px 4px 0 0
  background: $matik-white
  padding: 20px 24px
  p
    font-size: 14px
    font-weight: 600
    line-height: 24px
    color: $matik-black

.html-editor-header
  display: flex
  align-items: center
  justify-content: space-between

.auto-save-display
  padding: 0 1rem 1rem 0
  display: flex
  align-items: center
  justify-content: space-between
  img
    padding-right: 5px

.add-attachment-popup
  position: relative
  width: 100%

.add-attachment-popup-body
  height: 450px
  background-color: $matik-white
  border: 1px solid $grey-300
  box-sizing: border-box
  box-shadow: 0px 3px 12px rgba(0, 31, 53, 0.07), 0px 19px 38px rgba(0, 31, 53, 0.09)
  border-radius: 6px
  overflow-x: scroll

.subdomain-form
  display: flex
  width: 100%
  flex-direction: row
  .subdomain-input
    position: relative
    flex: 1
    align-self: stretch
    border-radius: 4px 0 0 4px
  .domain-suffix
    width: fit-content
    display: flex
    align-items: center
    background: $grey-100
    color: $grey-600
    padding: 8px 16px
    border: 1px solid $grey-300
    border-left: none
    border-radius: 0 4px 4px 0
    height: 2.5em
.json-section
  width: 100%
  margin-top: 24px
  .json-heading
    display: flex
    justify-content: space-between
    .json-copy
      display: flex
      align-items: center
      cursor: pointer
      svg
        fill: $blue-500
      .json-copy-text
        margin-left: 5px
        color: $blue-500
        cursor: pointer
  .json-box
    margin-top: 14px
    overflow-y: scroll
    height: 343px
    background: $matik-black
    border: 1px solid $grey-300
    border-radius: 4px
    color: $blue-500

.inline-warning-link
  text-decoration: underline
  cursor: pointer

.email-send-icon
  fill: $matik-white
  &.black
    fill: $matik-black
.email-settings-tab
  height: 100%
  overflow: hidden
.email-settings-tab-content
  margin: 0 auto
  height: 90%
  max-width: 100%
  padding: 20px
  display: flex
  justify-content: center
  background-color: $matik-white
  overflow-y: scroll
  .email-settings-center
    display: flex
    height: fit-content
    flex-direction: column
    align-items: flex-start
    width: 776px
    background: $matik-white
    flex: none
    order: 0
    align-self: stretch
    flex-grow: 0
    .email-form-field-header
      width: 100%
      height: 56px
      padding: 8px 24px 8px 24px
      border: 1px solid $grey-300
      border-radius: 6px 6px 0 0 
      display: flex
      align-items: center
      justify-content: space-between
      margin-top: 16px
      &.bottom-radius
        border-radius: 6px
      .email-form-field-header-left
        display: flex
        align-items: center
    .email-settings-collapsible-field
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: center
      border: 1px solid $grey-300
      border-top: none
      border-radius: 0 0 6px 6px
      width: 100%
      padding: 24px 24px
      .subject-field
        width: 100%
      .email-form-sub-field
        margin-bottom: 16px
        width: 100%
        .matik-select__value-container
          overflow: visible
          .matik-select__single-value
            width: 100%
        input
          width: 100%
        .email-form-help-text
          border-bottom: 1px dotted $grey-400
          width: fit-content
        .email-form-tabs
          width: fit-content
      .email-form-sub-field-header
        width: 100%
        display: flex
        align-items: center
        justify-content: space-between
        &.disabled
          pointer-events: none
          p
            color: $grey-500
          path
            fill: $grey-500
      .field
        width: 100%
        .label
          font-weight: 400
          font-size: $font-size-m
          line-height: 24px
          margin-bottom: 0
        .form-info-text
          font-weight: 400
          font-size: $font-size-m
          line-height: 24px
          color: $grey-600
        .input::placeholder
          color: $grey-600

.recipient-select-option
  display: flex
  align-items: center
  justify-content: space-between
  .recipient-select-option-left
    display: flex
    align-items: center
.select-dropdown-footer
  display: flex
  align-items: center
  justify-content: flex-start
  padding: 6px 12px
  border-top: 1px solid $grey-300
  gap: 10px