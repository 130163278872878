.avatars
  display: inline-flex
  flex-direction: row-reverse
  padding-left: 2px

  .UserAvatar
    color: white
    margin-left: -18px
    position: relative
    border: 3px solid white
    border-radius: 100px

.avatars-with-grey-border .avatars .UserAvatar
  border-color: $grey-100
