.trial-days-tag
  display: grid
  place-items: center

  .positive-trial-days
    background-color: $green-100
    color: $matik-black

  .low-trial-days
    background-color: $yellow-100
    color: $matik-black

  .warning-trial-days
    background-color: $red-100
    color: $matik-black

  .trial-days-chevron
    display: grid
    place-items: center
    margin-left: 0.3rem

.trial-days-dropdown
  width: 314px
  display: grid
  place-items: center
  white-space: normal !important

  .trial-dropdown-header
    color: $matik-black
    font-weight: 0
    font-size: 16px
    line-height: 21px
    text-align: center

  .trial-dropdown-content
    color: $grey-700
    font-weight: 0
    font-size: 14px
    line-height: 140%
    text-align: center

  .trial-dropdown-link
    color: $grey-700
    text-decoration: underline

.user-dropdown
  display: flex

  .user-dropdown-icon
    width: 17px
    height: 20px
    margin-bottom: 3rem

  .user-dropdown-plan
    color: $matik-black
    font-size: 16px
    line-height: 16px

  .positive-days-left
    color: $green-500

  .warning-days-left
    color: $red-500

  .plan-dropdown-link
    color: $grey-600
    text-decoration: underline

  .plan-info
    display: grid
    place-items: flex-start
    max-width: 150px
    white-space: normal
    padding-left: 1rem

.vertical-center
  display: flex
  place-items: center

.full-width
  width: 100%

.border-bottom
  border-bottom: 1px solid $grey-300
