.empty-slide
    border: 1px solid $grey-300
    background-color: $grey-100
    border-radius: 4px
    text-align: center

.center-message
    padding-top: 4%
    color: $grey-400
    position: relative

.error-pill
    margin-left: 220px
    margin-top: 10px