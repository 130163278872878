.product-upsell-modal
  display: grid
  place-items: center
  border-radius: 4px

  .warning-tag
    background-color: $red-100
    color: $red-500

  .product-upsell-header
    color: $matik-black
    font-weight: 300
    font-size: 22px
    line-height: 24px
    text-align: center

  .product-upsell-content
    color: $grey-600
    font-weight: 0
    font-size: 18px
    line-height: 24px
    text-align: center

  .product-upsell-link
    color: $grey-700
    text-decoration: underline
    font-size: 18px
    line-height: 24px
