.sortable-entity-grid
  display: grid
  grid-template-columns: repeat( auto-fill, minmax(280px, 1fr) )
  grid-column-gap: 16px
  grid-row-gap: 32px

  margin-left: 24px
  margin-right: 24px

.template-library-list
  .sortable-entity-grid
    display: grid
    grid-auto-flow: column
    grid-template-columns: repeat( auto-fill, minmax(230px, 1fr) )
    grid-column-gap: 16px
    grid-row-gap: 32px
    overflow-x: auto

    margin-left: 24px
    margin-right: 24px
    margin-bottom: 24px
    margin-top: 24px

.full-screen-modal .template-library-list .sortable-entity-grid
  margin: 24px 0
