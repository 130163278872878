.api-input-container
  background-color: $grey-100
  &:focus-within
    border-color: $matik-green
    box-shadow: 0 0 0 0.125em rgba($matik-green, 0.25)

  .api-method
    padding-right: .5rem
    height: 22px
    border-right: 1px solid $grey-300

  .base-url
    background-color: $grey-100
    padding-left: .5rem
    padding-right: .5rem
    height: 34px
    padding-top: 5px
    border-right: 1px solid $grey-300

  .api-input
    flex: 1
    height: 34px
    background-color: $input-background-color
    margin-right: calc(-1 * calc(0.75em - 1px))
    border-top-right-radius: $input-radius
    border-bottom-right-radius: $input-radius
    padding-right: 0.5rem

  div.api-input
    padding-top: 5px
    padding-left: calc(.5rem - 4px)

  input.api-input
    border: none
    font-size: .9rem
    padding-left: 0.5rem

    &:focus
      outline: none
