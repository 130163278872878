@import '../../matik-palette'

.prefixed-input
  padding-left: 24px

.input-prefix
  position: relative
  svg
    position: absolute
    top: 0
    left: 8px

.input-suffix
  position: relative
  svg, span
    position: absolute
    top: -2px
    right: 8px
    color: $grey-600

.pixel-input
  width: 64px
  background-color: transparent
  border-color: transparent
  box-shadow: none
  -moz-appearance: textfield
  &:hover
    border-color: $grey-300 !important
    box-shadow: none !important
.pixel-input::-webkit-outer-spin-button, .pixel-input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

