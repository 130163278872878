.stackable-side-modal
  background-color: rgba($matik-black, 0.8)
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: auto
  padding: 0 20px
  z-index: 100
  display: flex
  align-items: center
  flex-direction: column
  &.is-stacked
    background-color: rgba($matik-black, 0.0)
  .stackable-side-modal-content
    overflow: visible
    position: absolute
    margin: 1rem 1rem 0 0
    min-height: 95%
