@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS overrides while we do the migration */
svg,
img {
  display: inline;
  vertical-align: baseline;
}
