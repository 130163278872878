.uploaded-file-banner
  display: flex
  justify-content: space-between
  flex-direction: row
  padding: 12px
  margin: 24px 0
  border: 1px solid $grey-300
  border-radius: 4px
  box-shadow: 0 3px 6px 0 rgba(0, 31, 53, 0.06)
  .uploaded-file-icon
    width: 20px
    margin-right: 1rem
  .clear-uploaded-file
    cursor: pointer
    margin-top: 4px
