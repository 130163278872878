/* Overrides given radio styles to match mocks */

.green-radio-button
  cursor: pointer
  display: flex
  .radio-check
    height: 1rem
    width: 1rem
    border: solid 1px $grey-300
    border-radius: 50%
    margin: 2px 0.75rem 0px 0px
  input
    position: absolute
    opacity: 0
    cursor: pointer
    &:checked ~ .radio-check
      content: url('../../images/checkmark_white.svg')
      background-color: $matik-green
      border: solid 1px $matik-green
      transition: all .2s

.green-radio-button.disabled
  color: $grey-500
  .radio-check
    background-color: $grey-200