.plan-info
  display: table
  vertical-align: middle
  color: $grey-700
  font-weight: 0
  font-size: 14px
  line-height: 140%
  padding-top: 0.5rem

  .plan-info-content
    display: table-cell
    vertical-align: middle
    padding-left: 0.25rem

  .plan-link
    display: table-cell
    vertical-align: middle
    color: $grey-700
    text-decoration: underline

.connected-to-tag
  background-color: $white !important
  font-size: 14px !important

.connected-to-tag.empty
  color: $grey-400
.optional-text
  color: $grey-600

.auth-tabs
  border-top: 1px solid $grey-300
  border-left: 1px solid $grey-300
  border-right: 1px solid $grey-300
  background-color: $matik-white
  .tabs
    ul
      border-bottom: 1px solid $grey-300
      li:first-child
        a
          border-left: 0
        &.is-active
          a
            border-left: 0
      li
        a
          border-right: 1px solid transparent
          border-left: 1px solid transparent
          border-bottom: 1px solid $grey-300
        &.is-active
          background-color: $grey-100
          a
            border-right: 1px solid $grey-300
            border-left: 1px solid $grey-300
            border-bottom: 1px solid $matik-green
        &:hover a
          border-bottom: 1px solid $matik-green

.salesforce-report-select
  .matik-select__value-container
    .matik-select__single-value
      width: 100%

.data-source-box
  @extend .is-shadowless
  @extend .has-light-gray-border
  margin-top: 0.5rem !important
.data-source-body
  display: flex
  flex-direction: column
  justify-content: space-around
.field.data-source-footer
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 20px
  padding-top: 20px
  .data-source-buttons
    display: flex
    width: 50%
    justify-content: space-between
  .data-source-buttons.user-profile
    width: 320px
.missing-data-source-title
  margin-top: 40px
  font-size: 18px
  font-weight: 600

.merge
  border: 1px solid $grey-300
  height: 2.375rem
  border-bottom-left-radius: 0
  border-top-left-radius: 0
  max-width: none
.merge-no-radius
  border: 1px solid $grey-300
  height: 2.375rem
  max-width: none


.data-source-form-main
  background-color: $matik-white
  padding: 1rem 1rem 1rem 1rem
  height: 100%
  .data-source-form
    margin-top: -1.5rem
    padding-bottom: 1rem
  .form-button-cluster
    padding-right: 2rem

.dropdown-item
  display: flex
  align-items: center
  &:hover
    background: $dropdown-item-hover-background-color
  .dropdown-item-icon
    padding: 2px 11px 0 0

.data-source-main
  display: flex
  width: 100%
  flex-direction: column
  gap: 1rem

.data-source-grid-container
  display: flex
  flex-direction: column

.data-source-grid-header-title
  font-size: $font-size-m
  font-weight: $font-weight-regular
  color: $grey-600

.data-source-grid
  display: grid
  width: 100%
  grid-template-columns: repeat(2, 432px)
  column-gap: 1rem
  row-gap: 0.5rem
  padding-bottom: 0.5rem

.data-source-grid-add-first
  display: grid
  width: 100%
  grid-template-columns: repeat(3, 432px)
  column-gap: 1rem
  row-gap: 0.5rem
  padding-bottom: 0.5rem

.data-source-item
  display: flex
  flex: 50%
  background: $matik-white
  width: 432px
  height: 52px
  padding: 16px
  border: 1px solid $grey-300
  border-radius: $border-radius-small
  flex-direction: column
  justify-content: center
  &:hover
    border: 1px solid $grey-400
    cursor: pointer

.data-source-item img
  width: 28px
  height: 28px
  padding: 5px
  border-radius: $border-radius-small
  border: 1px solid $grey-300

.data-source-label
  font-size: $font-size-s
  color: #69778F
  text-align: center

.info-banner
  width: 880px

.info-banner-add-first
  width: 1328px

.data-source-content
  display: flex
  flex-direction: column
  gap: 1.75rem
  padding-bottom: 64px

.data-source-header-button
  display: flex
  flex-direction: row
  font-weight: $font-weight-semi-bold
  font-size: $font-size-l
  align-items: center
  justify-content: space-between

.data-source-dropdown
  display: flex
  flex-direction: row
  align-items: center
  gap: .70rem
  color: $matik-black
  .check
    color: $matik-green
    margin-left: auto

.data-source-dropdown img
  height: 20px
  width: 20px

.data-source-dropdown-select
  display: flex
  flex-direction: row
  align-items: center
  padding-left: 0.6rem

.data-source-dropdown-select img
  height: 20px
  width: 20px

.fullscreen-modal-data-source
  padding: 2rem
  padding-left: 6rem
  padding-right: 6rem
  display: flex
  width: 100%
  gap: 2rem

.fullscreen-data-source-form
  flex-grow: 5

.fullscreen-disclaimer
  margin-top: 1.45rem
  display: grid
  grid-template-columns: 20px, 1fr
  background: #F7F9FB
  height: 168px
  width: 300px
  padding: 2rem
  line-height: 26px
  column-gap: 0.3rem
  font-size: $font-size-m
  color: $matik-blue
  .fs-icon-lock
    grid-column: 1
    grid-row: 1
    width: 20px
    margin-top: 0.4rem
    display: flex
    justify-content: center
  .fs-icon-help
    grid-column: 1
    grid-row: 2
    width: 20px
    display: flex
    align-items: center
    justify-content: center
  .fullscreen-disclaimer-text
    grid-column: 2
    grid-row: 1
  .fullscreen-disclaimer-help-container
    grid-column: 2
    grid-row: 2
  .fullscreen-disclaimer-link
    font-size: $font-size-m
    color: $matik-blue
    text-decoration: underline
    &:hover
      font-size: $font-size-m
      cursor: pointer
      color: $grey-600

.auth-disclaimer
  font-size: $font-size-m
  cursor: pointer
  color: $grey-600
  padding-top: 0.5rem
  padding-bottom: 0.5rem