.input-form-main
    display: flex
    flex-direction: column
    flex: 1
    overflow-y: auto
    overflow-x: hidden
    margin-top: 56px
    .form-button-cluster
        padding-right: 2rem

.add-remove-buttons
    display: flex

.input-form-fullscreen
    width: 100%