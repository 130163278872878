.SingleDatePickerInput, .DateRangePickerInput__withBorder, .DateRangePicker_picker, .DayPicker, .SingleDatePicker_picker
  border-color: $grey-300
  border-radius: $input-radius
  display: flex
  align-items: center
  z-index: 100
  &:hover, &:active, &:focus
    border-color: $matik-green !important
    box-shadow: 0 0 0 1px rgba($matik-green, 0.25)
  .DateInput_input__focused
    border-bottom: 0
  .DateRangePickerInput_arrow
    display: flex
  table td, table th
    text-align: center
    vertical-align: middle
  .DateInput
    background-color: transparent
    .DateInput_input
      font-size: $size-6
      line-height: 1.5
      padding-top: 7.5px
      padding-bottom: 7.5px
      border-bottom: 0
      background-color: transparent
    .DateInput_fang
      display: none

  .DayPicker_weekHeaders
    div:last-child
      margin-left: 10px
  .DayPicker_weekHeader
    padding: 0 !important
    .DayPicker_weekHeader_li
      width: 42px !important
  .CalendarMonthGrid__horizontal
    left: 0
    div:nth-child(2)
      .CalendarMonth_table
        margin-right: 0.5rem
        margin-left: 0.25rem
    div:nth-child(3)
      .CalendarMonth_table
        margin-right: 1rem
        margin-left: 0.5rem
    .CalendarMonth_table
      border-collapse: separate
      border-spacing: 3px
  .CalendarMonth
    padding: 0 !important
    .CalendarMonth_caption
      padding-top: .5rem
      padding-bottom: .5rem
      background-color: transparent
      margin-bottom: 2.5rem
      border-bottom: 1px solid $grey-300
      border-right: 1px solid $grey-300
      strong
        font-size: $size-5
        font-weight: 300
    .CalendarDay__default
      border-color: transparent
      border-radius: 50px
    .CalendarDay__selected
      background-color: $blue-500
      color: white
    .CalendarDay__selected_span, .CalendarDay__hovered_span
      background-color: $blue-100
      color: $body-color
  .SingleDatePicker_picker, .DateRangePicker_picker
    z-index: 20
    top: 56px !important

.SingleDatePickerInput .DayPicker_weekHeader .DayPicker_weekHeader_li
  width: 42px !important
.DateRangePickerInput__withBorder .DayPicker_weekHeader .DayPicker_weekHeader_li
  width: 42px !important

.DayPickerNavigation_button, .DayPickerNavigation_button_1, .DayPickerNavigation_button__horizontal,.DayPickerNavigation_button__horizontal_2
  .left-navigation-button
    top: 15px
    position: absolute
    left: 22px
  .right-navigation-button
    top: 15px
    position: absolute
    right: 22px

.day-picker-popup
  position: absolute
  z-index: 101
  top: 0
  border: 1px solid $grey-300
  box-shadow: $box-shadow-medium
  border-radius: $border-radius-small
  .SingleDatePickerInput, .DateRangePickerInput__withBorder, .DateRangePicker_picker, .DayPicker, .SingleDatePicker_picker
    box-shadow: none
    &:hover, &:active, &:focus
      box-shadow: 0 0 0 1px rgba($matik-green, 0.25)
