// All the non palette variables should go here, eg border radius, box shadow, etc.

// border radius variables: https://www.figma.com/file/NY26AO5PS9JvgM7oqko1aa/Foundation?node-id=888%3A170
$border-radius-none: 0px
$border-radius-small: 4px
$border-radius-medium: 8px
$border-radius-large: 16px
$border-radius-full: 9999px

// box shadow variables: https://www.figma.com/file/NY26AO5PS9JvgM7oqko1aa/Foundation
$box-shadow-inline: inset 0px 0px 2px rgba(0, 0, 0, 0.12), inset 0px 1px 3px rgba(0, 0, 0, 0.06)
$box-shadow-none: none
$box-shadow-small: 0px 3px 6px rgba(0, 31, 53, 0.06)
$box-shadow-medium: 0px 1px 6px rgba(0, 31, 53, 0.08), 0px 3px 6px rgba(0, 31, 53, 0.06)
$box-shadow-large: 0px 2px 8px rgba(0, 31, 53, 0.08), 0px 10px 20px rgba(0, 31, 53, 0.06)
$box-shadow-xlarge: 0px 3px 12px rgba(0, 31, 53, 0.07), 0px 19px 38px rgba(0, 31, 53, 0.09)

// font-size variables: https://www.figma.com/file/NY26AO5PS9JvgM7oqko1aa/Foundation?node-id=1975%3A376
$font-size-s: 12px
$font-size-m: 14px
$font-size-l: 16px
$font-size-xl: 18px
$font-size-xxl: 24px
$font-size-xxxl: 28px
$font-size-xxxxl: 32px
$font-size-xxxxxl: 40px

// font-weight variables: 
$font-weight-regular: 400
$font-weight-semi-bold: 600
$font-weight-bold: 700

// illustration of what "max width" is
// notice how the main content doesn't extend beyond the red line, but banners + nav bar do extend beyond the red line
// https://drive.google.com/file/d/16j9KniDYEY-1QDXXXhIIb6RGg6Zp4_D0/view?usp=sharing
$max-width: 1240px