.editable-input
  display: flex
  align-items: center
  .editable-text
    border-bottom: 2px dashed $grey-300

.editable-field-editor
  position: absolute
  z-index: 30
  max-width: 592px
  .editable-input-input
    width: 500px
