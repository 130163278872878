.alert-content
  color: $grey-600
.archive-popup
  h2
    font-size: 1.5em
  ul
    list-style-position: inside
    li
      list-style-type: disc
      li::marker
        text-align: center !important
.no-perms-alert
  h2
    font-size: 1.5em
  .swal2-content
    font-size: 1em
  