#x-spreadsheet-div
  height: 292px
  &.full-screen
    height: 80vh
.drawer-bottom.drawer-open .drawer-content-wrapper
  box-shadow: 0 -12px 8px rgba($matik-black, 0.15) !important

.spreadsheet-container
  min-width: 100%
  position: fixed
  bottom: 0
  z-index: 101
  border-top: 1px solid $grey-300

  .horizontal-drawer-handle
    width: 100%
    height: 10px
    background-color: $white
    display: flex
    justify-content: center
    align-items: center
    border: 1px solid $grey-300

    .spreadsheet-drawer-horizontal
      margin: 0 auto
      width: 80px
      height: 2px
      background: $grey-300
      border-radius: 2px

  .vertical-drawer-handle
    width: 10px
    height: 100%
    background-color: $white
    display: flex
    justify-content: center
    align-items: center
    border: 1px solid $grey-300

    .spreadsheet-drawer-vertical
      width: 2px
      height: 80px
      background: $grey-300
      border-radius: 2px

.sheet-contents-loader
  min-height: 150px

.horizontal-footer
  width: 100%

.vertical-footer
  width: 440px

.google-sheet-footer
  height: 3rem
  background-color: $grey-100
  position: fixed
  bottom: 0
  border-top: 1px solid $grey-300

  .worksheet-selector
    padding: 1rem

  .worksheet-name-container
    align-self: start

  .worksheet-name
    cursor: pointer

  .footer-left
    flex: 1
    min-width: 0

  .workbook-name-list
    flex: 1
    overflow-x: auto

  .worksheet-name
    border: 1px solid transparent
    padding: 0.5rem
    padding-left: 0.8rem 
    padding-right: 0.8rem 
    &.active
      background: $white
      color: $green-700
      border: 1px solid $grey-300
      border-top: 0
      box-sizing: border-box
      border-radius: 0 0 2px 2px
      padding: 0.5rem
      padding-left: 0.8rem 
      padding-right: 0.8rem 

  .spreadsheet-link
    display: flex
    align-items: center
    font-size: 14px
    line-height: 24px
    color: $black

  .dock-button
    display: flex
    justify-content: center
    align-content: center
    flex-direction: column
    box-shadow: 0 3px 6px 0 $grey-300

  .dock-side-button
    height: 40px
    width: 40px
    margin-left: 0.75rem

    .dock-side-ellipses
      height: auto
      max-width: 300%

.spreadsheet-loading-error
  display: flex
  justify-content: center
  align-items: center
  min-height: 150px

  .spreadsheet-error-message
    padding-top: 0.25rem

.dock-side-dropdown
  margin-right: 0.75rem

.dock-side-menu
  padding-left: 1rem
  padding-right: 1rem

  .dock-menu-title
    color: $grey-600
    font-size: 12px
    line-height: 24px

  .dock-side-link
    display: flex
    color: $matik-black
    font-size: 14px
    line-height: 24px

    .dock-side-icon
      padding-right: 0.75rem

.drawer-close
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  width: 32px
  height: 32px
  right: 32px
  top: 0
  background: $white
  border: 1px solid $grey-300
  box-sizing: border-box
  box-shadow: 0 2px 8px rgba($matik-black, 0.08), 0 10px 20px rgba($matik-black, 0.06)
  border-radius: 50%
  z-index: 11

  .drawer-close-icon
    width: 11px
    height: 11px

.input-with-icon
  position: relative

  .input-box
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0

  .open-spreadsheet-icon
    width: 16px
    height: 16px
    position: absolute
    top: 13px
    right: 13px
    z-index: 3
