
.empty-state-banner-container 
  display: flex
  justify-content: center
  vertical-align: center
  margin-top: 50px
    
  .banner-content
    position: absolute
    width: 100%
    padding-top: 60px
    max-width: $max-width
    margin: auto

  .banner-content-disclaimer
    position: absolute
    width: 100%
    padding-top: 40px
    max-width: $max-width
    margin: auto

  .banner-content-disclaimer
    position: absolute
    width: 100%
    padding-top: 40px
    max-width: $max-width
    margin: auto

  .full-width
    width: 100%

  .video-thumbnail
    aspect-ratio: 16/9
    max-height: 150px
    box-shadow: $box-shadow-large
    border-radius: $border-radius-small

  .video-thumbnail-disclaimer
    margin-top: 20px
    aspect-ratio: 16/9
    max-height: 150px
    box-shadow: $box-shadow-large
    border-radius: $border-radius-small

  .banner-background
    height: 300px

  .banner-blurry-logo
    height: 300px
    position: absolute
    width: 600px
    right: 0

  .banner-arrow
    position: absolute
    height: 150px
    top: 150px
    left: 200px

  .banner-play-icon
    position: absolute
    height: 24px
    top: 135px
    left: 475px
    z-index: 2
    &:hover 
      cursor: pointer

  .btn-group
    display: flex
    gap: 10px

  .banner-icon 
    height: 16px
    width: 16px

  .banner-icon-container 
    height: 32px
    width: 32px
    border-radius: $border-radius-small
    display: flex
    align-items: center
    justify-content: center

  .green 
    background-color: $green-500
  
  .blue
    background-color: $blue-500

  .purple
    background-color: $purple-500

  .grey
    color: $grey-700
  
  .disclaimer
    color: $grey-600

  .disclaimer-link
    color: $grey-600
    text-decoration: underline
    &:hover
      cursor: pointer
      color: $grey-700

.lock-icon
  display: flex
  align-items: center

.templates-list-empty .empty-state-banner-container
  margin: 0 -1.5rem
  padding: 0 1.5rem
  width: auto
  .banner-content
    padding: 60px 1.5rem 0
    max-width: 1240px
  .video-link-templates
    padding: 0 1.5rem
    max-width: 1240px
    position: absolute
    width: 100%
    height: 100%
    margin: auto
    .banner-play-icon
      height: 24px
      width: 24px
      right: 155px
      top: 135px
      left: auto
.section.ptm .templates-list-empty
  margin-top: calc(56px - 1rem)
