html
  overflow-y: auto
body
  margin: 0
  padding: 0
  font-size: 0.9rem !important
.body
  width: 100%
  height: 100%
span.tag
  font-size: $font-size-m

html body.swal2-shown
  overflow: visible !important
.flex-1
  flex: 1
.is-vertical-centered
  align-items: center !important
.is-vertical-middle
  vertical-align: middle !important
.centered-button
  display: flex
  justify-content: center
.has-radius
  border-radius: 2px
.with-navbar
  padding-top: 65px
.is-borderless
  border: 0 !important
.is-circle
  border-radius: 50% !important
.button
  height: 2.375rem
.button.is-secondary
  border: 1px solid $grey-300
  box-shadow: 0px 2px 10px rgba($matik-black, 0.05)
  &:hover
    border: 1px solid $grey-300
    background: $grey-100
  &:focus
    border: 1px solid $grey-300
  &.is-selected
    background-color: $blue-200
  &:disabled
    border: 1px solid $grey-300 !important
    color: $grey-400
    svg.disabled-fill-path path,
    svg.disabled-fill-rect rect
      fill: $grey-400
    svg.disabled-fill-rect rect
      stroke: $grey-400
.button.is-tertiary
  border: 0px
  display: flex
  flex-direction: row
  align-items: center
  padding: 0px
  margin: 0px
  background: transparent
  color: $matik-black
  svg
    vertical-align: center
    path
      stroke: $matik-black
      stroke-width: 0.25px
  &:hover
    color: $grey-600
    svg path
      stroke: $grey-600
.button.is-invisible
  border: 0px
  min-width: 50px
.button.is-primary:hover
  background: $grey-700 !important
.button.is-primary.inert
  cursor: default
  &:hover
    background: $matik-black !important
.button.is-round-button
  border-radius: 2rem
  padding-left: calc(0.75em - 1px)
  padding-right: calc(0.75em - 1px)
.button.is-static
  color: $grey-600
  background-color: $grey-100
  border: 1px solid $grey-300
  width: 100%
.button.is-close
  background-color: transparent
  color: $grey-600
  padding: 0
  width: 20px
  height: 20px
  border: none
.button.is-static.right
  border-radius: 0px 4px 4px 0px
  border-left: none
.button.is-static.left
  border-radius: 4px 0px 0px 4px
  border-right: none
.button, button
  &.thin
    padding-left: calc(0.625em - 1px)
    padding-right: calc(0.625em - 1px)
  &.tall
    height: 38px
  &.small
    height: 2rem
  &.small.square
    width: 2rem
.button-span
  display: flex
  align-items: center
  justify-content: center
.api-field-container
  height: 14rem
  overflow: scroll
  margin-top: 1rem
.radio-add-padding
  input
    vertical-align: middle
    margin-left: .1rem
    margin-right: .5rem
.container
  .hero
    &.is-fullheight-with-navbar
      min-height: calc(100vh - .5rem)
.main .hero.is-fullheight-with-navbar
  min-height: calc(100vh - 3.6rem)
  .hero-body
    align-items: center
    display: flex
.control
  + .help
    margin-top: 6px
  input
    height: 2.5rem
  &.has-icons-left, &.has-icons-right
    .icon
      height: 2.5rem
.is-fullheight
  height: 100%
.is-fullwidth
  width: 100%
.media-content
  .column
    .big-tag
      height: 2.5rem
      padding: 1rem 2rem
.content-centered
  align-items: center
  justify-content: center
.centered-text
  text-align: center
.end-align
  justify-content: flex-end !important
.has-light-gray-border
  border: 1px solid $grey-300
  border-radius: 4px
.group:hover .group-hover\:has-green-border
  border: 1px solid $matik-green
.is-light-teal
  background-color: $green-400 !important
.is-dark-gray, .is-dark-gray-hover:hover
  color: $grey-600

.color-white
  color: $matik-white !important

.background
  &-white
    background-color: $matik-white !important
  &-orange
    background-color: orange !important
    border: 1px solid orange
  &-purple
    background-color: purple !important
    border: 1px solid purple
  &-darkcyan
    background-color: darkcyan !important
    border: 1px solid darkcyan
  &-blue
    background-color: $blue-500 !important
    border: 1px solid $blue-500
  &-lightcoral
    background-color: lightcoral !important
    border: 1px solid lightcoral
  &-green
    background-color: $matik-green !important
    border: 1px solid $matik-green
  &-gray
    background-color: $grey-400 !important
    border: 1px solid $grey-400

.is-relative
  position: relative
.is-absolute
  position: absolute !important

.block-button
  border-radius: 0 !important
  width: 100%
.bottom-notification
  position: absolute !important
  bottom: 1rem
  left: 1rem
  width: 15rem
.bottom-border
  border-bottom: 1px solid #ddd
.left-border
  border-left: 1px solid $grey-300
.right-border
  border-right: 1px solid $grey-300
.light-bottom-border
  border-bottom: 1px solid $grey-300 !important
.light-top-border
  border-top: 1px solid $grey-300 !important
.corner-icon
  border-right: $grey-300
.tab-content
  height: 85vh
.long-input
  min-width: 20rem
.params-title-heading
  border-bottom: 1px solid $grey-300
  padding: 0.7rem 1.5rem
  display: flex
  justify-content: space-between
  align-items: center
.field
  .label-with-button
    display: flex
    justify-content: space-between
    align-items: center
label.label
  &:not(:last-child)
    margin-bottom: 0
  & +.control
    margin-top: 0.25rem
  display: flex
  align-items: center
  color: $matik-black
  line-height: 24px
  font-size: $font-size-m
.align-self-center
  align-self: center
.has-text-orange
  color: $yellow-500 !important
.has-cursor-pointer
  cursor: pointer !important
.has-cursor-grab
  cursor: grab
.has-cursor-not-allowed
  cursor: not-allowed !important
.narrow-first-form
  max-width: 720px
.overflow-visible
  overflow: visible !important
.overflow-hidden
  overflow: hidden !important
.overflow-h-auto
  overflow-x: auto
.is-wrapped
  flex-wrap: wrap
.react-tooltip.matik-tooltip
  background-color: $matik-blue
  box-shadow: 0 4px 10px rgba($matik-blue, 0.1)
  border-radius: 6px
  opacity: 1
  padding: 12px
  hyphens: auto
  color: $grey-400
  z-index: 120
.react-tooltip.matik-tooltip strong
  color: $matik-white !important
  &.place-top
    margin-top: 0
  &:after
    content: ''
    border: 0
.is-narrow
  max-width: 800px
  margin: 0 auto
.tag.matik-tag
  background-color: $matik-white
  border: 1px solid $grey-300
  color: $grey-700
  margin-right: 0.5rem
  &.empty
    color: $grey-400
.min-width-0
  min-width: 0
.text-overflow-ellipsis
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
.control.clickable-icon
  pointer-events: none
  input, .icon
    pointer-events: auto
.is-large-button
  line-height: 1 !important
.tag-button
  height: 28px
  padding: 0 !important
.green-checkmark
  color: $matik-green
.red-exclamation
  stroke: $red-500
  fill: $red-500
.yellow-exclamation
  stroke: $yellow
  fill: $red-500
.matik-toast
  width: auto
  min-width: 230px
  .Toastify__toast
    border-radius: 50px
    min-height: 40px
    &.Toastify__toast--error
      .Toastify__toast-body
        background: no-repeat url('images/close_white.svg')
        background-position: 0.75rem 0.5rem
    &.Toastify__toast--success
      .Toastify__toast-body
        background: no-repeat url('images/checkmark_white.svg')
        background-position: 0.75rem 0.5rem
    .Toastify__toast-body
      text-align: center
      padding-left: 1rem
      padding-left: 2.5rem
      padding-right: 1rem
.banner-toast
  width: 50%
  .Toastify__toast
    padding: 0
    min-height: 0
    .Toastify__toast-body
      padding: 0
.icon-pull-down
  margin-bottom: -3px
.sidebar-icon-pull-down
  margin-bottom: -6px
.favorites-icon
  margin-bottom: -3px
  margin-left: 10px
  cursor: pointer
.icon-size-20
  height: 20px
  width: 20px
div.loading-container
  display: block
  position: absolute
  left: 0
  right: 0
  background: rgba($matik-white,0.8)
  transition: all .3s ease
  top: 0
  bottom: 0
  text-align: center
  z-index: 10

  .loading-helper
    display: inline-block
    height: 100%
    vertical-align: middle
  .loading-img
    vertical-align: middle

.centered-loading
  width: 100%
  justify-content: center

.message-with-button
  display: flex
  justify-content: space-between
  align-items: center

div.appcues appcues-container[data-pattern-type='shorty']
  box-shadow: none

.daterange-picker-icon-left
  position: absolute
  left: 22px
  top: 18px

.daterange-picker-icon-right
  position: absolute
  right: 22px
  top: 18px



.ptn
  padding-top: 0 !important
.ptxxs
  padding-top: .125rem !important
.ptxs
  padding-top: .25rem !important
.pts
  padding-top: .5rem !important
.ptm
  padding-top: 1rem !important
.ptl
  padding-top: 1.5rem !important
.ptxl
  padding-top: 2rem !important
.ptxxl
  padding-top: 2.5rem !important
.pbxl
  padding-bottom: 2rem !important
.pbxxl
  padding-bottom: 2.5rem !important
.pbxxxl
  padding-bottom: 3rem !important
.pb4xl
  padding-bottom: 3.5rem !important
.pbl
  padding-bottom: 1.5rem !important
.pbm
  padding-bottom: 1rem !important
.pbs
  padding-bottom: .5rem !important
.pbn
  padding-bottom: 0 !important
.prn
  padding-right: 0 !important
.prxs
  padding-right: 0.25rem !important
.prs
  padding-right: 0.5rem !important
.prm
  padding-right: 1rem !important
.prl
  padding-right: 1.5rem !important
.prxl
  padding-right: 2rem !important
.pln
  padding-left: 0 !important
.plxs
  padding-left: 0.25rem !important
.pls
  padding-left: 0.5rem !important
.plm
  padding-left: 1rem !important
.pll
  padding-left: 1.5rem !important
.plxl
  padding-left: 2rem !important
.pvn
  padding-top: 0 !important
  padding-bottom: 0 !important
.pvxs
  padding-top: 0.25rem !important
  padding-bottom: 0.25rem !important
.pvs
  padding-top: 0.5rem !important
  padding-bottom: 0.5rem !important
.pvm
  padding-top: 1rem !important
  padding-bottom: 1rem !important
.pvl
  padding-top: 1.5rem !important
  padding-bottom: 1.5rem !important
.pvxl
  padding-top: 2rem !important
  padding-bottom: 2rem !important
.phn
  padding-right: 0 !important
  padding-left: 0 !important
.phs
  padding-right: .5rem !important
  padding-left: .5rem !important
.phm
  padding-right: 1rem !important
  padding-left: 1rem !important
.phl
  padding-right: 1.5rem !important
  padding-left: 1.5rem !important
.pas
  padding: .5rem
.pam
  padding: 1rem !important
.pal
  padding: 1.5rem !important
.paxl
  padding: 2rem !important
.pan
  padding: 0 !important
.mtn
  margin-top: 0 !important
.mtxxs
  margin-top: .125rem !important
.mtxs
  margin-top: .25rem !important
.mts
  margin-top: .5rem !important
.mtm
  margin-top: 1rem !important
.mtl
  margin-top: 1.5rem !important
.mtxl
  margin-top: 2rem !important
.mtxxl
  margin-top: 2.5rem !important
.mtxxxl
  margin-top: 3rem !important
.mbxs
  margin-bottom: .25rem !important
.mbs
  margin-bottom: .5rem !important
.mbm
  margin-bottom: 1rem !important
.mbl
  margin-bottom: 1.5rem !important
.mbxl
  margin-bottom: 2rem !important
.mbxxl
  margin-bottom: 2.5rem !important
.mbxxxl
  margin-bottom: 3rem !important
.mb4xl
  margin-bottom: 4rem !important
.mbn
  margin-bottom: 0 !important
.mrxs
  margin-right: .25rem
.mrs
  margin-right: .5rem !important
.mrm
  margin-right: 1rem !important
.mrl
  margin-right: 1.5rem !important
.mrxl
  margin-right: 2rem !important
.mr-auto
  margin-right: auto
.mlxs
  margin-left: 0.25rem
.mls
  margin-left: .5rem
.mlm
  margin-left: 1rem
.mll
  margin-left: 1.5rem
.ml-auto
  margin-left: auto
.mhn
  margin-left: 0 !important
  margin-right: 0 !important
.mhxs
  margin-left: 0.25rem !important
  margin-right: 0.25rem !important
.mhs
  margin-left: 0.5rem !important
  margin-right: 0.5rem !important
.mhm
  margin-left: 1rem
  margin-right: 1rem
.mhl
  margin-left: 1.5rem
  margin-right: 1.5rem
.mvxs
  margin-top: .25rem
  margin-bottom: .25rem
.mvs
  margin-top: .5rem
  margin-bottom: .5rem
.mvm
  margin-top: 1rem !important
  margin-bottom: 1rem !important
.mvl
  margin-top: 1.5rem
  margin-bottom: 1.5rem
.mvxl
  margin-top: 2rem
  margin-bottom: 2rem
.mam
  margin: 1rem
.mal
  margin: 1.5rem
.man
  margin: 0 !important
.bbs
  bottom: 0.5rem !important
.bbm
  bottom: 1rem !important
.bbl
  bottom: 1.5rem !important
.bbxl
  bottom: 2rem !important
.bbxxl
  bottom: 2.5rem !important
.bbxxxl
  bottom: 3rem !important
.is-secondary-description
  color: $grey-600
.display-none
  display: none !important
.overflow-y-auto
  overflow-y: auto
.dropdown-columns
  width: 370px
.dropdown-image-with-overlay
  position: relative
  height: 80px
  width: 100%
.dropdown-image-with-overlay .after
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  opacity: 0.9
  background-color: $matik-green
  border-radius: 4px
.dropdown-image-with-overlay .after-text
  color: $matik-white
  font-weight: 700
  font-size: 18px
  position: absolute
  left: calc(50% - 15px)
  top: calc(50% - 15px)
.input-filter-icon
  width: 30px
  height: 27px
  border: 2px solid $grey-300
  border-bottom-left-radius: 10px
  border-right: 0
  border-top: 0
.input-filter-icon-button
  cursor: default !important
.consumer-section
  padding-top: 5.5rem
.consumer-section-with-sidebar
  padding-top: 3.5rem
.templates-list-offset
  height: calc(100vh - 16px) !important
.narrow-sidebar
  width: 314px !important
  min-width: 314px !important
.refresh-container
  position: absolute
  top: 4rem
  padding: 1rem
  z-index: 50
  width: 100%
  display: flex
  justify-content: center
  .refresh-message
    background-color: $matik-black
    border-radius: 4px
    padding: 1rem
    color: $grey-100
.end-user-templates-list
  .filters-container
    .filters-overlay
      top: 56px !important
.info-caption
  display: flex
  flex-direction: row
  padding: 12px 16px
  gap: 8px
  background-color: $blue-100
  border-radius: 4px
  svg
    color: $blue-500
.help
  font-size: $font-size-s
  margin-top: 0
  color: $grey-600
  line-height: 16px
  min-width: 6rem
  & +.control
    margin-top: 0.25rem !important
.help.is-danger
  position: relative
  width: fit-content
  padding-left: 5px
  margin-top: 6px
.help.inputs-list-help
  margin-top: 0
.help.is-danger.analytics
  position: absolute
.help.input-mapping
  padding-left: 5px
  margin-top: 6px
.icon-select
  .matik-select__value-container
    padding-left: 40px
    .icon-select-icon
      position: absolute
      left: 18px
.input-pill-mapping-dropdown
  .input-pill-mapping-dropdown-title
    color: $grey-600
  .label
    margin: 0
  .dropdown-content
    padding-bottom: 0.9rem
    padding-top: 0.9rem
    min-width: 240px
  p.input-pill-mapping-text:not(.help)
    position: relative
    margin-top: 0.75rem
    padding-left: 2.5rem
    padding-right: 0.9rem
    svg.input-mapping-hover-icon
      position: absolute
      top: 12px
      left: 16px

.product-upsell-close-button
  font-size: 28px
  color: $grey-400
  top: 20px
  right: 20px
  &:hover
    color: $grey-400

.product-upsell-image-container
  width: 100%
  height: 278px
  padding-top: 19px
  overflow: hidden
  background: linear-gradient(181.75deg, #7EF4AE -74.83%, #20DFAC 38%, #AD20DF 221.92%)
  border-top-left-radius: 6px
  border-top-right-radius: 6px
  img
    border-top-left-radius: 6px
    border-top-right-radius: 6px
    border: 1px solid $grey-300
    box-shadow: 0px 10px 20px 0px #001F350F, 0px 2px 8px 0px #001F3514
.product-upsell-image-container.full-image
  padding-top: 45px
  img
    border-bottom-left-radius: 6px
    border-bottom-right-radius: 6px

.enterprise-upsell-tag, .template-library-template-tag
  background: linear-gradient(278deg, #20DFBD 10.92%, #20DFAC 49.2%, #7EF4AE 95.02%)
  color: $blue-700 !important
  padding: 3px 6px
  font-weight: 900 !important
  font-size: 9px !important
  line-height: 120% !important
  cursor: pointer

.template-library-template-tag
  left: 23.75px
  top: 21px
  position: absolute

.template-input-mapping-icon
  margin-bottom: -0.25rem

.punch-viewer-container
  background-color: $grey-300
  .punch-viewer-content
    border: none

.rdw-editor-main
  padding: 0.7rem
  line-height: 1.5
  .public-DraftStyleDefault-block
    margin: 0.5px
  .rdw-center-aligned-block > .public-DraftStyleDefault-block
    text-align: center
  .rdw-right-aligned-block > .public-DraftStyleDefault-block
    text-align: right

.container.has-text-centered.narrow-container // need the specificity to override bulma container media queries
  max-width: 500px

.gap-small
  gap: 4px
.gap-2
  gap: 8px
.gap-3
  gap: 12px
.gap-medium
  gap: 16px
.gap-large
  gap: 32px

.react-table-pagination-container
  float: right

.sidebar .react-table-pagination-container
  float: none

.dropdown-content
  .is-disabled
    pointer-events: none
    opacity: .65

.is-full-width
  width: 100%
.is-flex
  display: flex
.space-between
  justify-content: space-between
.space-around
  justify-content: space-around
.flex-start
  justify-content: flex-start
.flex-end
  justify-content: flex-end
.is-grow
  flex-grow: 1
.is-flex-col
  flex-direction: column

.popup-box
  background-color: $matik-white
  border-radius: $border-radius-small
  border: 1px solid $grey-300
  box-shadow: 0px 3px 6px 0px #001F350F, 0px 1px 6px 0px #001F3514

.w-5
  width: 1.25rem
.h-5
  height: 1.25rem
.h-14
  height: 3.5rem

#dropdown-portal
  .matik-select__option.matik-select__option--is-disabled
    color: $grey-300
    cursor: not-allowed
    .disabled-message
      color: $grey-600

.is-transparent
  background: transparent

.font-s
  font-size: $font-size-s
  line-height: calc($font-size-s + 2px)
.font-m
  font-size: $font-size-m
  line-height: calc($font-size-m + 2px)
.font-l
  font-size: $font-size-l

.is-uppercase
  text-transform: uppercase

.top-0
  top: 0
.right-0
  right: 0

.opacity-50
  opacity: 0.5
.magic-gradient path
  fill: url(#magic-gradient)
