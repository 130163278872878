// This should reflect the palette defined at:
// https://www.figma.com/file/NY26AO5PS9JvgM7oqko1aa/Foundation?node-id=866%3A65
// If you find yourself using any other colors, please double check with the design team

// Neutral
$matik-black: #001F35
$matik-green: #20DFAC
$matik-blue: #101D42
$matik-white: #FFFFFF

// Grey
$grey-50: #FBFCFD
$grey-100: #F7F9FB
$grey-200: #EFF1F6
$grey-300: #E0E5EE
$grey-400: #A5B4CD
$grey-500: #8898B4
$grey-600: #69778F
$grey-700: #3D4A60

// Blue
$blue-100: #F3F9FE
$blue-200: #D8E8F7
$blue-300: #ACD1F4
$blue-400: #84B6F1
$blue-500: #3279EF
$blue-600: #205EBB
$blue-700: #004585

// Red
$red-100: #FFFBFC
$red-200: #F9D8E3
$red-300: #F2B1C6
$red-400: #E8769B
$red-500: #DF3C71
$red-600: #B2305B
$red-700: #8F2347

// Green
$green-100: #F7FFFA
$green-200: #CCF2DB
$green-300: #B4E8C8
$green-400: #88E4AC
$green-500: #2ACCAF
$green-600: #2FA875
$green-700: #11836E 

// Yellow
$yellow-100: #FFFCF7
$yellow-200: #FFF0D6
$yellow-300: #FFDC9E
$yellow-400: #FCC25D
$yellow-500: #F69D00
$yellow-600: #B87600
$yellow-700: #7B4F00

// Purple
$purple-100: #F6F4FF
$purple-200: #E1DBFF
$purple-300: #C8BDFF
$purple-400: #9580FF
$purple-500: #7B61FF
$purple-600: #5640C8
$purple-700: #3D2D8F