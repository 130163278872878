// For some reason this doesn't compile without this import even though this file is imported in App.sass
@import "../../../matik-palette"
.menu-list a.is-active
  background-color: $blue-100
  color: initial
.menu-list a:not(.is-active) svg
  fill: $grey-600
.tag-filter
  font-size: 14px !important
  color: $matik-black !important
  background: $grey-300 !important
  cursor: pointer
  user-select: none
  -webkit-user-select: none
.tag-filter.is-active
  background: $matik-black !important
  color: $matik-white !important
.template-tags
  .level
    display: block
.menu.is-disabled
  pointer-events: none
  opacity: .65
