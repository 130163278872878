div#queryLoader
  color: $grey-500
  font-size: 14px
  svg
    vertical-align: -5px
  div.LottieContainer
    display: flex
    alignment: left
  #lastSynced
    opacity: 0
    &:hover
      opacity: 100%
