.payments-header
  display: block
  margin-left: auto
  margin-right: auto
  margin-top: 40px

  .back-button
    display: flex
    flex-direction: row
    align-items: center
    position: absolute
    width: 96px
    height: 40px
    left: 40px
    top: 40px
    border: 1px solid $grey-300
    box-sizing: border-box
    border-radius: 4px
    background: $body-background-color
    cursor: pointer

.payment-form
  display: flex
  justify-content: center
  grid-template-columns: repeat( auto-fill, minmax(280px, 1fr) )
  grid-column-gap: 32px
  padding-top: 4rem

  .credit-card-info
    width: 573px
    height: 452px
    padding: 2rem
    border-radius: 8px
    border: 1px solid $grey-300
    box-sizing: border-box
    box-shadow: 0 0 0

    .credit-info-header
      color: $matik-black
      font-weight: bold
      font-size: 18px
      line-height: 24px

    .credit-input-label
      color: $matik-black
      font-weight: 1
      font-size: 16px
      line-height: 16px

    .shortened-input
      width: 241px

    .cvc-field
      width: 265px

    .stripe-credit-input
      border: 1px solid $grey-300
      height: 2.5rem
      border-radius: 4px
      padding-top: 0.7rem
      padding-left: 0.7rem

    .payment-form-submit
      font-size: 16px
      line-height: 16px

  .plan-summary
    width: 406px
    height: 330px
    padding: 2rem
    border-radius: 8px
    border: 1px solid $grey-300
    box-sizing: border-box
    box-shadow: 0 0 0

    .plan-summary-header
      color: $matik-black
      font-weight: 600
      font-size: 22px
      line-height: 24px
      padding-bottom: 1.5rem

    .plan-tier-name
      color: $matik-black
      font-size: 16px
      line-height: 16px

    .plan-billing-cycle
      color: $grey-600
      font-weight: 0
      font-size: 14px
      line-height: 140%

    .plan-summary-price
      color: $matik-black
      font-weight: 600
      font-size: 22px
      line-height: 24px
      text-align: right
      padding-left: 8rem

    .plan-summary-hr
      height: 1px
      color: $grey-300

  .expiry-cvc-capture
    display: flex
    justify-content: flex-start

  .payment-info
    font-weight: 1
    font-size: 14px
    line-height: 140%
    display: flex
    justify-content: center
    padding-top: 0.5rem

  .incomplete-message
    color: $grey-600

  .card-error
    color: $red-500

  .payment-success
    color: $matik-green

.tier-comparison
  .enterprise-info
    display: block
    padding-top: 4rem
    padding-bottom: 6rem

    .enterprise-info-inner
      display: grid
      place-items: center
      margin-bottom: 0.5rem

    .enterprise-avatar
      display: grid
      place-items: center
      color: $input-background-color
      font-weight: 300
      font-size: 22px
      line-height: 24px
      padding-bottom: 1rem

    .enterprise-info-subtitle
      color: $grey-700

  .tier-card-grid
    display: flex
    justify-content: center
    grid-template-columns: repeat( auto-fill, minmax(280px, 1fr) )
    grid-column-gap: 24px
    grid-row-gap: 32px
    margin-left: 24px
    margin-right: 24px

.tier-card
  width: 378px
  background: $grey-100
  box-shadow: none
  border: 1px solid $grey-300
  box-sizing: border-box
  border-radius: 8px
  margin-bottom: 4rem

  .tier-card-header
    background-color: $input-background-color
    border-bottom: 1px solid $grey-300
    box-sizing: border-box
    border-radius: 8px
    box-shadow: none
    height: 272px
    display: block

    .tier-title
      padding-top: 2rem
      margin-bottom: 0.25rem
      color: $matik-black
      font-weight: bold
      font-size: 24px
      line-height: 32px

    .tier-description
      font-size: 16px
      line-height: 130%
      color: $grey-600
      padding-bottom: 1.5rem
      margin-right: 1rem

    .tier-cost
      display: flex
      margin-bottom: -1rem

    .tier-price
      display: inline
      color: $matik-black
      font-weight: 900
      font-size: 36px
      line-height: 36px
      padding-left: 0.25rem

    .tier-price-interval
      color: $matik-black
      font-size: 14px
      line-height: 14px
      padding-left: 0.5rem
      padding-top: 1.25rem

    .tier-price-description
      color: $grey-600
      font-size: 14px
      line-height: 14px
      justify-content: left
      padding-left: 0.5rem

    .tier-select
      padding-top: 1.5rem

  .tier-card-content
    background: $grey-100
    margin-bottom: 1rem

    .tier-content-header
      color: $grey-700
      font-weight: 600
      font-size: 20px
      line-height: 24px
      padding-top: 1rem
      padding-bottom: 0.5rem

    .feature-li
      list-style-image: url('../../svg/check_bullet.svg')
      padding-bottom: 0.5rem

      .feature-li-span
        display: inline-table
        vertical-align: middle
        padding-left: 0.5rem
        padding-bottom: 1rem

      .feature-extra-info
        display: inline-table
        vertical-align: middle
        padding-bottom: 0.5rem

        .beta-tag
          background-color: $grey-100
          color: $grey-600 !important
          border: 1px solid $grey-600 !important

        .more-info
          height: 15px
          width: 15px

.coming-soon-tag
  background-color: $grey-100
  color: $matik-green !important
  border: 1px solid $matik-green !important

.tier-compare-link
  text-decoration-line: underline
  color: $grey-600
  padding-left: 0.5rem

.feature-comparison
  margin-left: 128px
  margin-right: 128px
  cursor: default

  .header-hr
    height: 1px
    background-color: $grey-300
    margin-bottom: 0

  .active
    box-shadow: 0 8px 8px -8px $grey-300

  .feature-comparison-header
    display: flex
    padding-top: 5rem
    position: sticky
    top: 0
    background-color: $grey-100
    z-index: 10
    padding-bottom: 1rem

    .tier-cta
      width: 100%

    .tier-title
      color: $matik-black
      font-weight: bold
      font-size: 24px
      line-height: 32px
      padding-bottom: 0.5rem

    .feature-comparison-header-title
      width: 100%
      font-weight: bold
      font-size: 32px
      line-height: 110%
      color: $matik-black

  .feature-comparison-section-header
    color: $matik-black
    font-weight: bold
    font-size: 18px
    line-height: 24px
    padding-top: 3rem
    padding-left: 2rem
    margin-bottom: 2rem

  .feature-label
    color: $matik-black
    font-size: 18px
    line-height: 24px

  .white-row
    background-color: $input-background-color
