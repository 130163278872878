.tab-content
  .below-header
    margin-top: 56px
.wrapper
  display: flex
  height: calc(100vh - 1px)
  position: relative

.main
  flex: 1
  overflow-y: auto
  overflow-x: hidden
  margin-top: 56px
  padding: 0 1.5rem
  display: flex
  flex-direction: column
  .header
    background-color: $matik-white
    padding: 1rem 1.5rem 1rem 1rem
    box-shadow: 0 1px 3px rgba(53, 53, 53, 0.12)
    position: relative
    z-index: 1
    .title
      color: $matik-black
      line-height: 24px
    .level-left
      flex-shrink: 1
      min-width: 0
      .level-item:last-child
        flex-shrink: 1
        min-width: 0

.sidebar
  width: 440px
  min-width: 440px
  margin-top: 56px
  .box
    padding: 0
    display: flex
    flex-direction: column
    border-right: 1px solid $grey-300
    background-color: $grey-100
  .list-entry
    padding-left: 2.5rem
    padding-right: 2.5rem
    &:hover
      background-color: $grey-100
  .card-selected
    border-left: 2px solid $matik-green
    padding-left: calc(1.5rem - 2px) !important
    background-color: $grey-100

.create-sidebar
  height: 100%
  width: 464px
  position: relative
  left: 0px
  background: $matik-white
  form
    height: calc(100% - 72px)
    overflow-y: auto
    width: inherit
    padding: 24px
    .loop-icon
      width: 2rem
      height: 1.5rem
    &.email
      height: 100%
