.filters-container
  position: relative
  width: 100%
  .filters-overlay
    position: absolute
    width: 100%
    right: 0
    z-index: 100

.component-params .filters-container .filters-overlay
  top: 40px

.sidebar .filters-container .filters-overlay .level, .component-params .filters-container .filters-overlay .level, .dynamic-content-sidebar .filters-container .filters-overlay .level
  flex-direction: column
  padding: 1rem 0
  margin: 0 1rem
  .level-item
    margin-top: .5rem
    width: 100%

.control.has-icons-right .icon.is-right.tag.filter-tag
  width: 5rem
  margin: .25rem
  height: 2rem
  justify-content: center
  padding-right: 0.5rem
  padding-left: 0.5rem
  pointer-events: all
