.iframe-wrap
  padding: 0
  overflow: hidden
  aspect-ratio: 1.8 / 1
.async-rounded-top
  display: block
  margin-left: auto
  margin-right: auto
  border-radius: 4px 4px 0px 0px
  &.template-card-iframe
    width: calc(100% * 3)
    height: calc(100% * 10)
    border: 0
    transform: translate(-33%, -24%) scale(0.55)

.async-rounded
  display: block
  margin-left: auto
  margin-right: auto
  border-radius: 4px

.popup-thumbnail-wrap
  display: flex
  justify-content: center
  position: relative
  aspect-ratio: 1.8/1
  .image-wrapper
    height: 100%
    width: auto
    .popup-thumbnail
      border: 1px solid $grey-300
      filter: drop-shadow(0px 3px 6px rgba(0, 31, 53, 0.06))
      border-radius: 4px
      border: 1px solid $grey-300
      border-radius: 4px
      max-width: 100%
      max-height: 100%
      width: auto
      height: auto
      object-fit: contain