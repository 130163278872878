.tab-header
  padding: 0 0 1rem 0
  display: flex
  align-items: center

.tab-header.inverted
  background-color: white
  padding: 0 0 0.5rem 0
  .selected
    color: $matik-black

.tab-header-label
  display: flex
  justify-content: center
  align-items: center

.tab-header-item
  color: $grey-400
  font-size: 14px
  font-weight: 400
  height: 48px
  padding: 0 12px
  display: flex
  align-items: center
  border: 1px solid $grey-300
  border-right: none
  background: $matik-white
  display: inline-flex
  svg, path
    fill: $grey-400
  span
    padding: 12px 0
  &.is-active, &.selected
    color: $matik-black
    box-sizing: border-box
    span
      border-bottom: 2px solid $matik-green
    svg, path, rect
      fill: $matik-black
  &:hover
    border-right: 1px solid $grey-400 !important
    border: 1px solid $grey-400
    + a
      border-left: none
  &:first-child
    border-radius: 4px 0 0 4px
  &:last-child
    border-radius: 0 4px 4px 0
    border-right: 1px solid $grey-300
