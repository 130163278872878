/* Multi-line editor */

.ace-matik {
  font-size: 1em;
  line-height: 1.5em;
  font-family: inconsolata, monospace !important;
  letter-spacing: 0.3px;
  background: #f6f9fc;
  color: #000000;
}

.ace-matik .ace_gutter {
  background-color: #f6f9fc;
  border-right: 1px solid #dfe5ec;
}

.ace-matik .ace_selection {
  background: #a7caff;
}

.ace-matik .ace_comment {
  font-style: italic;
  color: #3c802c;
}

.ace-matik.ace_autocomplete .ace_scroller {
  padding-top: 0px;
}

.ace-matik .ace_gutter-active-line {
  background-color: #f6f9fc;
}

.ace-matik .ace_keyword,
.ace-matik .ace_keyword.ace_operator,
.ace-matik .ace_support.ace_function {
  color: #ff55c5;
}

.ace-matik .ace_constant.ace_numeric,
.ace-matik .ace_string {
  color: #29b2fe;
}

.ace-matik .ace_marker-layer .ace_active-line {
  background-color: #dfe5ec;
  opacity: 0.5;
}

/* Single-line input */

.ace_editor.ace-matik-one-line {
  min-height: auto;
}
.ace-matik-one-line {
  font-size: 1em;
  line-height: 1.5em;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', ui-monospace, monospace; /* ace requires a monospaced font */
  letter-spacing: 0.3px;
  background: #ffffff;
  color: #363636;
}

.ace-matik-one-line .ace_comment.ace_placeholder {
  color: #69778f !important;
  font-style: normal;
  margin: 0;
}

.ace-matik-one-line .ace_hidden-cursors {
  opacity: 0;
}

.ace-matik-one-line .ace_selection {
  background: #a7caff;
}
.ace-matik-one-line .ace_comment {
  font-style: italic;
  color: #3c802c;
}

.ace-matik-one-line .ace_constant.ace_numeric {
  color: #7520af;
}

.ace-matik-one-line .ace_string {
  color: #881181;
}

.ace-matik-one-line .ace_keyword.ace_operator {
  color: #aa2063;
}

/* We want these to be used regardless of theme so input and dynamic content tags
 * are consistently highlighted.
 */

.ace_editor {
  min-height: 200px;
}

.ace_editor .ace_input,
.ace_editor .ace_dynamic-content {
  color: #ed7722;
}
.ace_editor .ace_matik-user-input {
  color: #2fa875;
}
.ace_editor .ace_input.ace_valid-text,
.ace_editor .ace_matik-user-input.ace_valid-text {
  text-decoration: underline;
}
.ace_editor .ace_invalid-text {
  text-decoration: underline dashed red;
}

/* 
  Somewhat hacky way to hide the cursor in read-only mode, since Ace doesn't give us a 
  way to do it directly. We switch to using the "slim" cursor style when read-only.
*/
.ace_slim-cursors {
  display: none;
}

/* reset ".control input" */
.control input.ace_search_field {
  height: auto;
}
