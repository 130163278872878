.template-title-header
  display: flex
  flex-direction: row
  padding: 1rem 2rem
  background-color: $grey-100
  color: $blue-500
  border-bottom: 1px solid $grey-300
  align-items: center
  justify-content: space-between

  img
    height: 25px

.columns
  .column
    .components-wrapper
      height: calc(100vh - 140px)
      overflow-y: auto
      position: fixed
      width: 32%
      .component-params
        padding-top: .75rem


.param-wrapper
  display: flex
  position: relative
  min-width: 0
  .is-child
    border-left: 2px solid $grey-300
    position: absolute
    margin-top: .25rem
    height: 100%
    min-width: 0
  .last-child
    border-left: 2px solid $grey-300
    border-bottom: 2px solid $grey-300
    border-bottom-left-radius: 10px
    width: 2rem
    margin-bottom: 2rem
    margin-top: .25rem
    margin-right: .5rem
.param-wrapper .param-card, .edit-tag .param-card, .modal-content .param-card, .param-card
  background-color: $matik-white
  height: 5rem
  padding: 0.75rem 1rem
  margin: .25rem 0
  border: 1px solid $grey-300
  border-radius: 4px
  flex: 1
  &:hover, &.hover
    border: 1px solid $matik-green
  .tpn-preview
    color: $grey-600
    width: 290px
  .tpn-input
    width: 300px
  .tpn-component-name
    color: $matik-black
  &.uni-sub
    padding: 0.75rem 1rem
  &.bi-sub
    padding: 0.25rem 1rem

.slides-wrapper .template-grid .slide-view
  .react-loading-skeleton
    padding-top: 5px

.conditions-icon
  width: 2rem
  height: 2rem
  border-radius: 1rem
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  padding: 0

.conditions-title-icon
  width: 40px
  height: 40px
  border-radius: 20px
  padding: 9px

.loop-icon
  width: 2rem
  height: 2rem
  border-radius: 1rem
  color: white
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  padding: 0
.icon-color
  &-orange
    background-color: orange
    border: 1px solid orange
  &-purple
    background-color: purple
    border: 1px solid purple
  &-darkcyan
    background-color: darkcyan
    border: 1px solid darkcyan
  &-blue
    background-color: $blue-500
    border: 1px solid $blue-500
  &-lightcoral
    background-color: lightcoral
    border: 1px solid lightcoral
  &-green
    background-color: $matik-green
    border: 1px solid $matik-green
  &-gray
    background-color: $grey-400
    border: 1px solid $grey-400

.slide-close-icon
  position: absolute
  right: -0.5rem
  top: -.75rem
  z-index: 1
  width: 1.5rem
  height: 1.5rem
  border-radius: 1rem
  background-color: white
  color: $matik-black
  border: 1px solid $grey-300
  display: none
  justify-content: center
  align-items: center
  cursor: pointer



.unmatched-icon
  width: 2rem
  height: 2rem
  border-radius: 1rem
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  padding: 0
  &.is-last
    right: 1rem

  img
    width: 2rem
    height: 2rem

.libraries-icon
  z-index: 2
  width: 2rem
  height: 2rem
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  padding: 0

.template-card-thumbnail-container .libraries-icon
  right: .5rem
  position: absolute

.modal
  .access-container
    max-height: 400px
    overflow: auto

  .add-template-button
    display: block
    width: 100%

  .choose-template-file
    background: $body-background-color
    border: 1.5px dashed $grey-300
    border-radius: 4px
    text-align: center
    color: $grey-700
    padding: 1.5rem
    margin-top: 1rem
    display: flex
    flex-direction: column

    .choose-template-icon
      height: 64px

    .ppt-upload
      cursor: pointer

  .template-file-upload
    border: 1px solid $grey-300
    border-radius: 4px
    box-shadow: 0 1px 2px rgba($matik-black, 0.05)
    padding: 0.5rem 0.5rem 0.5rem 0.75rem
    margin-top: 1.5rem
    margin-bottom: 1.5rem

    .file-name
      border: none
      padding-left: 0

    .template-file-close
      color: $grey-600
      height: 15px
      cursor: pointer

.template-slides
  display: flex
  flex-direction: column
  width: auto
  flex: 1
  overflow: hidden
  position: relative
  &.slide-grid
    overflow-y: auto
  .template-banners
    margin-right: 60px
  .right-side-menu
    position: fixed
    right: 2rem
    top: 10rem
    background-color: transparent
    text-align: right
    z-index: 2
    button
      width: 36px
      height: 36px
      border-radius: 50%
      padding: 0
      margin-top: -8px
      path, svg
        fill: $matik-black
  &.email
    overflow: auto
    background-color: $matik-white
  &.document
    overflow: auto
  .slides-wrapper
    flex: 1
    overflow-y: auto
    padding-top: 2rem
    padding-left: 1rem
    .template-grid
      display: flex
      justify-content: start
      flex-wrap: wrap

.template-slides:not(.create-templates)
  .template-grid
    padding: 0 8%

.slide-wrapper
  display: flex
  justify-content: center
  align-items: stretch
  box-sizing: border-box
  margin-bottom: 2.5rem
  cursor: pointer
  &.is-opaque
    opacity: 0.2
  &:hover
    .slide-close-icon
      display: flex
  .slide-view
    position: relative
    .image-wrapper
      border-radius: 6px
      border: 1px solid $grey-300
      img
        border-radius: 4px
        max-height: 150px
  &.selected
    .slide-view
      .image-wrapper
          border: 2px solid $matik-green !important
          box-shadow: 0 0 2px 3px $green-200
  .skeleton-image-wrapper
    width: 100%

.slide-number-wrapper
  display: flex
  flex: 1
  width: 100%
  justify-content: center
  color: $grey-600
  padding-top: 0.5rem

.insert-slides-wrapper
  .template-grid
    .slide-wrapper
      display: inline-block
      margin-right: 1rem
      .slide-view
        .image-wrapper
          img
            max-width: 150px
    .slide-wrapper:nth-child(4n)
      margin-right: 0

.insert-slide
  position: relative
  width: 30px
  cursor: auto
  .icon-wrapper
    display: none
  &.show
    display: flex
    &:after
      content: ""
      position: absolute
      top: 0
      bottom: 0
      left: calc(50% - 1px)
      border: 1px dashed $grey-300
    .icon-wrapper
      position: absolute
      display: flex
      top: calc(50% - 1rem)
      left: 50%
      margin-left: -14px
      border: 1px solid $grey-300
      border-radius: 40px
      padding: 5px
      cursor: pointer
      background-color: $matik-white
      z-index: 10
      .insert-slide-icon
        color: $grey-700
      .disabled-gray
        color: $grey-300
  .grid-header
    margin-bottom: 0
    align-items: flex-start
    padding: .75rem 2rem

.consumer-template-main
  .insert-slide
    &.is-last
      position: absolute
      right: -2rem

.pdf-container
  margin: 0
  display: flex
  flex-direction: column
  width: 70%


.overlay-wrapper .textLayer, .react-pdf__Page .textLayer
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  transform-origin: 0 0
  line-height: 1
  z-index: 6
  span
    position: absolute
    color: transparent
    white-space: pre
    transform-origin: 0 0
.annotationLayer
  position: absolute
  top: 0
  left: 0
  pointer-events: none
  transform-origin: 0 0

.overlay-wrapper
  position: absolute
  z-index: 1
  .page-element-wrapper
    border-radius: 2px
    &.with-tags
      border: 2px dashed $grey-300
    &.hover, &.highlighted
      .page-element-overlay
        cursor: pointer
      border: 2px dashed $matik-green
      .page-element-overlay-icon.add
        display: flex
  .page-element-wrapper .page-element-overlay-icon, .page-element-add-text-wrapper .page-element-overlay-icon
    position: absolute
    right: 0
    top: 0
    transform: translate(50%, -50%)

  .page-element-add-text-wrapper .page-element-overlay-icon
    cursor: pointer
  .page-element-wrapper .page-element-overlay-icon.add
    display: none

.insert-library-slide-popup-content
  background-color: $matik-white
  border-radius: 4px
  border: 1px solid $grey-300
  box-shadow: 0 4px 10px rgba($matik-black, 0.1)
  width: 700px
  height: 375px
  overflow-y: auto

  padding: 1rem
  padding-bottom: 4rem

  .select-wrapper
    width: 300px

  .popup-bottom-bar
    position: absolute
    bottom: 0
    left: 0
    background-color: $matik-white
    border: 1px solid $grey-300
    border-radius: 4px
    width: 700px
    padding: 1rem

.template-slides .library-button-info
  background-color: $grey-100
  border-color: $grey-300
  box-shadow: none

.template-slides .clickable-library-button
  box-shadow: none
  &:hover
    background-color: $matik-white

.description-overlay
  position: relative
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  .description
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    display: none
    position: absolute
    background-color: $matik-black
    color: white
    height: 100%
    width: 100%
    padding: 1rem
    overflow: hidden
    z-index: 5

  &:hover
    .description
      display: block
  .image-wrapper
    width: 100%
    display: flex
    justify-content: center
    img
      width: auto
      height: 100%
.empty-libraries-wrapper
  display: flex
  justify-content: center
  img
    height: 200px

.modal .scheduled-modal
  form .field
    width: 100%
.modal.scheduled
  .help.column
    flex: none

.input-with-addon
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  border: 1px solid $grey-300
  border-radius: $border-radius-small
  &:focus-within, &:active, &:hover
    border-color: $matik-green
    box-shadow: 0 0 0 0.125em rgba(32, 223, 172, 0.25)
  .input
    border: none
    border-radius: 4px 0 0 4px
    box-shadow: none
.input-addon
  display: flex
  justify-content: center
  align-items: center
  line-height: 1.5rem
  background-color: $grey-100
  border-radius: 0 4px 4px 0
  border-left: 1px solid $grey-300
  padding: 6px 16px

.tpn-menu-item
  .icon
    width: 32px
    height: 32px
    margin-right: 8px
  .tpn-menu-description
    color: $grey-600
    line-height: 1rem
    font-size: $font-size-s
  .tpn-menu-name
    line-height: 1rem

.add-template-body
  width: 80%
  max-width: 1200px
  height: 80%
  margin: 0 auto
  margin-top: 100px
  display: flex
  flex-direction: column
  align-items: center
  h3
    font-size: $font-size-xxl
  .add-template-cards
    width: 100%
    margin-top: 24px
    display: grid
    gap: 24px
    grid-template-columns: 1fr 1fr
    .narrative-card
      display: flex !important
      justify-content: center !important
      align-items: center !important
      height: 316px
      border-radius: 6px
      padding: 32px
      gap: 16px
      border: 1px solid $grey-300
      box-sizing: border-box
      .narrative-card-icon
        width: 100px
        height: 100px
        background: $blue-100
        border-radius: 8px
        display: flex
        img
          width: 66px
          height: auto
          margin: auto auto
          border: none
          box-shadow: none
          padding: 0
          border-radius: $border-radius-none
      .narrative-card-center
        width: auto
        display: flex
        flex-direction: column
        border: none
        justify-content: center
        align-items: center
        height: auto
        padding: 0
        .narrative-card-title
          font-size: $font-size-xl
          color: $black !important
          font-weight: 600
          line-height: 24px
          margin-top: 0
        .narrative-card-subtitle
          font-size: $font-size-m
          line-height: 24px
          text-align: center
          color: $grey-600
          margin: 0
          height: 48px
    > div
      background-color: $matik-white
      height: 230px
      border: 1px solid $grey-300
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: flex-start
      padding: 24px
      border-radius: 6px
      flex-grow: 1
    img
      border: 1px solid $grey-200
      border-radius: 100px
      box-shadow: 0px 3px 6px rgba(0, 31, 53, 0.06)
      padding: 10px
    p
      margin-top: 16px
      font-weight: 400
      font-size: $font-size-m
      line-height: 24px
      color: $grey-600
      height: 48px
    .import-icon
      width: 18.33px
      height: 18.33px
      border: none
      box-shadow: none
      padding: 0
      margin-right: 10px

.template-file-picker-input
  display: none

.template-wrapper
  display: flex
  flex-grow: 1
  min-height: 0
.template-components
  padding: 1.5rem
  min-width: 400px
  background-color: $matik-white
  width: 440px
  overflow: scroll
  overflow-y: auto
  overflow-x: hidden
  border-right: 1px solid $grey-300
.templates-header
  width: 100%
  padding: 1rem
  background-color: $white
  .level-left
    flex-shrink: 1
    min-width: 0
    .level-item:nth-child(2)
      flex-shrink: 1
      min-width: 0
      margin-right: 1rem

.dc-content-title
  max-width: 325px
  font-size: 0.875rem !important
  &.with-edit
    max-width: 292px

.dc-content-formats
  max-width: 320px

.dc-content-title-child
  max-width: 280px

.dc-content-formats-child
  max-width: 278px

.template-content-list
  width: 50%

.input-display-name
  max-width: 280px
  display: block !important
  cursor: pointer !important

.email-sidepane
  margin-top: -920px
.email-modal-sidepane
  margin-top: -1000px
.template-wrapper-with-sheet
  display: flex
  height: 100%
  min-height: 0
  position: relative
.template-with-sheet-sidepane
  width: 50%
  min-width: 650px
  max-width: 800px
  height: calc(100vh - 3.5rem - 350px) !important
  margin-top: -4.5rem
  position: absolute
  border-left: 1px solid $grey-300
  top: 0
  right: 0
  z-index: 1
  .dynamic-content-form-with-sheet
    height: 100%
    max-width: 100%
    overflow: scroll
    overflow-y: auto
    overflow-x: hidden
    margin-left: 0


span.view-buttons
  height: 164px
  width: 37px
  position: fixed
  right: 0
  bottom: 1px
  border-left: 1px solid $grey-300
  border-top: 1px solid $grey-300
  border-top-left-radius: 4px
  z-index: 2
  .view-button
    height: 81.5px
    padding: 2rem 0.7rem
    border: unset
    border-radius: 0
    display: block
    position: relative
    cursor: pointer
    &:focus
      box-shadow: none
    &:active
      box-shadow: none
    path, svg
      fill: $matik-black
  .view-button.top
    border-bottom: 1px solid $grey-300
  .view-button.bottom svg
    margin-left: -3px
  .view-button:not(.active)
    background-color: $grey-100
    path, svg
      fill: $grey-400
  .view-button.active
    background-color: $matik-white
    &:after
      content: ""
      width: 2px
      height: 40px
      background-color: $matik-green
      position: absolute
      right: 0
      top: 21px
      border-radius: 1px

.template-actions-dropdown > .dropdown-menu
  width: 240px
  .dropdown-item[title="hidden"]
    display: none


// SINGLE SLIDE VIEW SPECIFIC CSS AND OVERRIDES

.hero.ssv
  height: 100%
  .hero-body
    height: calc(100% - 226px)
    overflow-y: scroll
    padding: 0 3rem
  .hero-foot
    overflow-y: hidden
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-template-rows: auto 1fr auto
    grid-template-areas: "main main main"
    flex-grow: 1
    height: 102px
    border-top: 1px solid $grey-300
    background-color: $matik-white
  .footer
    bottom: 0
    z-index: 1
    background-color: $matik-white
    overflow-y: hidden
    height: 168px
    padding-right: 0
    padding-left: 0
    padding-top: 1rem
    grid-area: main
    margin: 0
  .slides-slider
    position: relative
    width: 100%
    overflow-x: scroll
    overflow-y: hidden
    white-space: nowrap
    user-select: none
    cursor: pointer
    height: 211px
    display: flex
    margin-left: -16px
    padding-left: 1.5rem
    .slide-thumbnail-container
      display: flex
      margin-left: 1rem
      &.is-last
        padding-right: 4rem
      .slide-wrapper
        margin: 0
        position: relative
        .slide-view
          display: flex
          flex-direction: column


        .insert-slide
          position: relative
          width: 1rem
          height: 97px
          &.is-last
            position: absolute
            right: -1rem

    .slide-thumbnail-container .slide-thumbnail-number
      border-bottom: 2px solid transparent
      align-items: center
    .slide-thumbnail-container.viewing .slide-thumbnail-number
      border-color: $matik-green
    .image-wrapper.slider-thumbnail-wrapper,
    .image-wrapper.skeleton-image-wrapper
      width: 171px
      height: 97px
      border: 1px solid $grey-300
      border-radius: 4px
      padding: 0
      display: inline
      cursor: pointer
      pointer-events: none
      .slide-thumbnail.slider-thumbnail
        height: 100%
        width: auto
        border: none
        margin: 0 auto
      .react-loading-skeleton
        top: -5px
    .slide-thumbnail-number
      display: flex
      flex-grow: 1
      justify-content: center
      color: $grey-600

.fullscreen-conditions-form
  width: 60%
  margin: 60px auto
.template-condition-icon
  background: $blue-500
  border-radius: 100px
  width: 40px
  height: 40px
  padding: 10px
  gap: 10px
  cursor: pointer
  path
    fill: $matik-white

.modal-card-title .subtitle
  color: $grey-600
  font-size: 14px
  line-height: 21px
  font-weight: 400

.slide-selector .overlay-button
  position: absolute
  inset: 0
  border-radius: $border-radius-small
  z-index: 2
  color: $matik-white
  background-color: rgba(0, 0, 0, .9)
  svg path
    fill: $matik-white
  opacity: 0
  &:hover
    opacity: 1
svg.fill-green path
  fill: $matik-green
