.color-picker-swatch
  box-sizing: border-box
  display: flex
  flex-direction: row
  align-items: center
  padding: 4px 8px 4px 8px
  gap: 8px

  width: 110px
  height: 36px
  background: transparent
  border: 1px solid transparent
  border-radius: 4px
  flex: none
  order: 1
  flex-grow: 0
  &:hover
    border-color: $grey-300 !important
  &:active, &:focus, &:focus-within
    cursor: pointer
    border-color: $matik-green !important
    box-shadow: 0 0 0 1px rgba($matik-green, 0.25)

.color-picker-swatch-preview
  width: 16px
  height: 16px
  border-radius: 8px
  border: 1px solid $grey-300

  flex: none
  order: 0
  flex-grow: 0

.color-picker-swatch-preview-hex
  color: $matik-black
  font-size: 14px

.color-picker-wrapper
  position: relative
  cursor: pointer
  .color-picker-popover
    position: absolute
    z-index: 3
  .color-picker-popover.up
    bottom: 42px
    left: -230px
  .color-picker-popover.down
    bottom: -294px
    left: -222px
  .rcp
    border: 1px solid #E0E5EE
    border-radius: 10px
    box-shadow: 0px 1px 6px rgba(0, 31, 53, 0.08), 0px 3px 6px rgba(0, 31, 53, 0.06)
    border-radius: 4px
    z-index: 2

.branding-container
  .color-picker-popover.up
    left: 0
  .color-picker-popover.down
    left: 0

.color-picker-color
  width: 40px
  height: 40px
  border-radius: 3px
  padding-left: 4px
  background: rgba(var(--color-r), var(--color-g), var(--color-b), var(--color-a))

.hex-input
  border: 0px
  width: 70px
  box-shadow: 0px 
  outline: none !important
  &:active, &:focus, &:hover, &:focus-within
      outline: none !important
      border: 0px !important
      box-shadow: 0px 