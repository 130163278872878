.about-me-survey
  text-align: center
    
.signup-survey-card
  display: block
  margin-left: auto
  margin-right: auto
  width: 601px
  height: 720px
  background-color: $input-background-color
  border-radius: 8px
  box-shadow: 0px 3px 10px rgba($matik-black, 0.08), 0px 14px 28px rgba($matik-black, 0.08)
  overflow: scroll
  &::-webkit-scrollbar
    width: 0 !important
    
    
.about-me-header-icon
  height: 80px
  width: 80px
  margin-top: 20px

.signup-survey-title
  font-size: 22px

.survey-label
  margin-left: 65px
  font-weight: 400

.survey-input
  width: 489px
  height: 40px

.survey-input::placeholder
    color: $grey-600
    
.survey-select
  width: 489px
  margin-left: 56px

.survey-submit-button
  width: 489px
  margin-top: 20px
