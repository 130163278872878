.upload-container
  width: 100%
  background-color: $grey-50
  div.dropzone
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    padding: 20px
    position: relative
    border-width: 1px
    border-radius: 4px
    border-color: $grey-300
    border-style: dashed
    color: $grey-600
    outline: none
    transition: border .24s ease-in-out
    width: 100%
    height: 220px
    .upload-icon
      width: 72px
      margin: .5rem 0
