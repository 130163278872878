.wysiwyg-button
    border: 1px solid $grey-300
    background-color: $matik-white
    &:hover
        box-shadow: none
        border: 1px solid $grey-500

.wysiwyg-button-color
    padding-top: 3px
    height: 26px
    width: 23px
    margin-left: 4px
    margin-right: 3px
    border: 1px solid $grey-300
    border-radius: 2px
    background-color: $matik-white
    &:hover
        box-shadow: none
        border: 1px solid $grey-500
    &.active
        box-shadow: 1px 1px 0px #BFBDBD inset

.autocomplete-suggestion
    padding: 4px 16px 4px 16px
    overflow-y: auto
    background-color: $matik-white

.autocomplete-suggestion.active
    background-color: #F1F1F1

.wysiwyg-textarea
    max-width: 100%
    display: block
    padding: 0px
    background-color: transparent
    h1
        font-size: 2.25rem !important
        font-weight: 700 !important
    h2
        font-size: 1.875rem !important
        font-weight: 700 !important
    h3
        font-size: 1.5rem !important
        font-weight: 700 !important
    h4
        font-size: 1.25rem !important
        font-weight: 600 !important
    h5
        font-size: 1rem !important
        font-weight: 400 !important
    h6
        font-size: 0.875rem !important
        font-weight: 400 !important
    &:not([rows])
        max-height: unset

.rich-text-component
    border: 1px solid $grey-300
    font-family: 'RM Neue'
    font-style: normal
    font-weight: 400
    font-size: 14px
    min-width: 45px
    &.font-family
        display: flex
        width: 140px
        height: 30px
        padding-left: 5px
        align-items: center
        justify-content: space-between
    & a
        color: $matik-black
        cursor: pointer
        text-decoration: none
    &:hover
        box-shadow: none
        border: 1px solid $grey-500
    &.dropdown
        display: flex
        flex-direction: column
        z-index: 100
        position: relative
        border: 1px solid #F1F1F1
        width: 99%
        background: white
        border-radius: 2px
        margin: 0
        padding: 0
        max-height: 250px
        overflow-y: auto
        & li
            padding-top: 5px
            padding-bottom: 5px
            min-height: unset
    &.font-dropdown
        display: flex
        flex-direction: column
        z-index: 100
        position: relative
        width: 100%
        background: white
        border: 0px
        margin: 0
        padding: 5px
        max-height: 250px
        &:hover
            background: #f5f5f5
        &.active-font
            background: #f5f5f5

.dropdown-carettoopen
    height: 0px
    width: 0px
    position: relative
    margin-right: 10px
    margin-bottom: 3px
    border-top: 6px solid black
    border-left: 5px solid transparent
    border-right: 5px solid transparent

.dropdown-carettoclose 
    height: 0px
    width: 0px
    position: relative
    margin-right: 10px
    margin-bottom: 3px
    border-bottom: 6px solid black
    border-left: 5px solid transparent
    border-right: 5px solid transparent

.rich-text-component-dropdown-container
    position: absolute
    top: 37px
    left: 123px
    border: 1px solid #F1F1F1
    z-index: 100
    width: 195px
    height: 400px
    overflow-y: auto
    overflow-x: hidden

.color-tab-popover
    position: absolute
    z-index: 3
    border: 1px solid #E0E5EE
    box-shadow: 0px 1px 6px rgba(0, 31, 53, 0.08), 0px 3px 6px rgba(0, 31, 53, 0.06)
    border-radius: $border-radius-medium
    background-color: $matik-white
    
.color-picker-backdrop
    background-color: none
    width: 100%
    height: 100%
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 1

.color-picker
    margin-top: 5px

.color-picker-tabs
    display: flex
    justify-content: space-evenly
    overflow: hidden
    z-index: 2
    &:not(:last-child)
        margin-bottom: unset
  
.color-picker-tab-buttons
    cursor: pointer
    padding: 8px
    margin: 0
    border: none
    background: none
    font-size: 14px
    outline: none
    position: relative
    width: 35%
    &.active-tab
        border-bottom: 2px solid $matik-green