.progress-bar
  display: flex
  border-radius: $border-radius-large
  border: 1px solid $grey-300
  height: 14px
  width: 100%
  padding: 3px
  margin: 8px 0
  &.run-error
    background-color: $red-100
    border: 1px solid $red-200
  div
    height: 100%
    width: 100%
    border-radius: $border-radius-large
    position: relative
    flex-shrink: 0
    &.not-started
      background-color: $grey-200
    &.done, &.sent
      background-color: $matik-green
    &.warning
      background-color: $yellow-500
    &.error, &.unsent_error
      background-color: $red-500
    &.sent_override
      background-color: $green-600
    &.unsent_condition, &.failed_condition
      background-color: $blue-500