.param-in-form
  padding-bottom: 1rem
.end-user-test-token-button-container
  display: flex
  justify-content: flex-end
  flex-direction: column
  .end-user-test-token-button
    border-top-left-radius: 0
    border-bottom-left-radius: 0
    border-left: none
    height: 2.5rem
.end-user-auth-token-input-control
  width: calc(100% - 109px) !important
  .end-user-auth-token-input
    border-top-right-radius: 0
    border-bottom-right-radius: 0
