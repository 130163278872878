.create-sidebar .fixed-bottom-button
  background: linear-gradient(180deg, rgba($matik-white, 0) 0%, $grey-100 100%)
  position: absolute
  bottom: 0
  pointer-events: none
  width: 480px
  z-index: 2
  padding: 1rem
  border-top: 1px solid $grey-300
  height: 4.5rem
  right: 0
  background: $grey-100
  .block-button
    border-radius: 4px !important
    pointer-events: all

.create-templates.template-slides .slides-wrapper
  width: auto
  padding-top: 2rem
  padding-left: 1rem
  height: calc(100vh - 134px)
  .template-grid .slide-wrapper
    flex: 0 0 300px
    position: relative
    .insert-slide
      width: 2rem
      height: 154px
.create-templates.template-slides .slides-wrapper .template-grid .slide-wrapper .select-slide, .insert-slides-wrapper .select-slide
  position: absolute
  box-shadow: 0 4px 10px rgba($matik-black, 0.1)
  border-top-left-radius: 8px
  border-bottom-right-radius: 2px
  border-color: $grey-100
  bottom: 1px
  right: 0
  padding: .25rem .5rem
  background-color: white

.complete-container
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  .generated-file-wrapper
    max-width: 785px
    margin: 0 auto
    width: 100%
  .rename-container
    max-width: 724px
    margin: 0 auto
    text-align: start
