.html-builder-container
    display: flex
    flex-direction: row
    gap: 1rem
    div.bottom-buttons
        z-index: 11
        background: transparent

.html-builder-view
    min-height: 40vh
    width: 100%
    border: 1px solid $grey-300
    border-radius: $border-radius-small

.main-builder-container,
.main-builder-container > div:nth-child(1),
.main-builder-container > div:nth-child(1) > div:nth-child(1)
    min-height: 40vh
    .html-builder-text-input
        z-index: 10
        width: 100%
        height: auto
        position: relative
        border: none
        outline: 0
        resize: none
        padding: 0.25rem 0.5rem

.html-builder-empty-state-container
    display: flex
    justify-content: center
    align-items: center

.html-builder-empty-state
    display: flex
    justify-content: center
    align-items: center
    height: 80px
    width: 600px
    user-select: none
    background: $grey-100
    border: 1px dashed $grey-400
    border-radius: $border-radius-small
    color: $matik-black

.html-builder-empty-state.drag-over
    border: 1px dashed $blue-500
    background: $blue-100
    color: $blue-500

.html-builder-column-empty-state
    display: flex
    justify-content: center
    align-items: center
    user-select: none
    background: $grey-100
    color: $grey-600
    height: 100%
    min-height: 100px

.html-builder-column-empty-state.drag-over
    background: $blue-100
    color: $blue-500

.html-builder-image-empty-state
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    user-select: none
    background: $grey-50
    color: $matik-black
    font-size: $font-size-s
    height: 200px
    width: 100%
    padding: 2rem
    gap: 0.35rem
    &.dc
        background: #EBFCF1

.modules-header
    font-size: $font-size-m
    font-weight: $font-weight-regular
    margin-bottom: 1rem
    margin-top: 1rem
    color: $grey-600

.modules-center-text
    display: flex
    justify-content: center
    font-size: $font-size-m
    font-weight: $font-weight-regular
    margin-bottom: 1rem
    margin-top: 1rem
    color: $matik-black

.toolbox
    display: grid
    width: 100%
    grid-template-columns: repeat(2, 192px)
    column-gap: 1rem
    row-gap: 0.5rem

.toolbox-button 
    display: flex
    flex: 50%
    background: $matik-white
    width: 192px
    height: 40px
    border: 1px solid $grey-300
    border-radius: $border-radius-small
    flex-direction: column
    justify-content: center
    &:hover
        border: 1px solid $grey-400
        cursor: pointer

.toolbox-button .toolbox-button-icons > div svg
    margin-left: 12px
    margin-right: 6px

.toolbox-button .toolbox-button-icons > div > svg path
    fill: $grey-600

.toolbox-button .toolbox-button-icons > div:first-child svg
    margin-left: 8px
    margin-right: 0



.toolbox-button-text
    font-size: $font-size-m
    color: $matik-black
    margin-left: 0
    align-items: center

.toolbox-button-icons
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center

.ratio-button-icons
    display: flex
    gap: 0.25rem
    width: 100%
    flex-direction: row
    align-items: center
    justify-content: flex-start

.toolbox-header
    text-align: center
    font-size: $font-size-l
    font-weight: $font-weight-semi-bold

.module-settings-top-bar
    display: flex
    flex-direction: row
    justify-content: space-between
    font-size: $font-size-m
    font-weight: $font-weight-semi-bold
    margin-top: -0.25rem
    padding-bottom: 0.5rem
    margin-right: -1.75rem
    color: $matik-black

.module-divider
    height: 1px
    background: $grey-300
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    margin-left: -24px
    margin-right: -24px

.module-settings-divider
    height: 1px
    background: $grey-300
    margin-top: 1rem
    margin-bottom: 1rem

.module-setting
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    font-size: $font-size-m
    color: $matik-black
    gap: 0.5rem
    &.border-label
        align-items: flex-start
    .alignment-button
        background-color: transparent
        border-color: transparent
        margin-top: 0.5rem
        cursor: pointer
        &.selected
            background-color: $blue-100
            border-color: $blue-300
    .chevron-icon
        display: flex
        justify-content: flex-end
        width: 21px
        color: $grey-600 !important
    &.top-margin
        margin-top: 1rem
    &.set-height
        height: 36px

.module-setting.w-full
    span
        width: 100%
        input
            width: 100% !important

.module-settings-container
    display: flex
    flex-direction: column
    gap: 0.25rem
    margin-bottom: 2rem
    div.custom-select__value-container
        justify-content: right
    input:not(.html-builder-text-input), div.custom-select__control
        background-color: transparent
        border-color: transparent
        box-shadow: none
        &:hover
            border-color: $grey-300 !important
            box-shadow: none !important
    .image-width-input
        text-align: right
        height: 38px
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    .image-width-type-wrapper
        width: 60px
        .matik-select-image-width-type__control
            border-top-left-radius: 0
            border-bottom-left-radius: 0

.module-settings-select
    width: 25%

.module-settings-input
    width: 30%
    border: 1px solid $grey-300
    border-radius: 4px
    box-shadow: 0 1px 2px rgba($matik-black, 0.05)
    padding: 0.5rem 0.5rem 0.5rem 0.75rem
    &:active, &:focus, &:hover, &:focus-within
        outline: none !important
        border: 1px solid $matik-green !important
        box-shadow: 0 0 0 1px rgba($matik-green, 0.25)

.main-container-padding-input
    width: 56px

.column-settings-container
    display: flex
    flex-direction: column
    gap: 0.25rem

.html-builder-history
    background-color: $matik-blue
    padding: 10px 20px
    height: 58px
    button, a
        cursor: pointer
        color: $matik-white
        background-color: transparent
        border-color: transparent
        outline: 0
        margin-right: 0.5rem
        path
            fill: $matik-white
        &:hover
            color: $grey-400
            background-color: transparent
            border-color: transparent
            outline: 0
            path
                fill: $grey-400
        &:active, &:focus
            color: $blue-400
            background-color: transparent
            border-color: transparent
            outline: 0
            box-shadow: none !important
            path
                fill: $blue-400
        &:disabled
            color: $grey-600
            background-color: transparent
            border-color: transparent
            outline: 0
            path
                fill: $grey-600

.image-tab-picker
    color: $matik-black
    font-size: 14px
    font-weight: 400
    width: 85px
    height: 34px
    padding: 0 8px
    display: flex
    align-items: center
    border: 1px solid $grey-300
    background: $matik-white
    span
        padding: 5px 0
    &.static
        border-radius: 4px 0px 0px 4px
    &.dynamic
        border-radius: 0px 4px 4px 0px
        border-left: none
    &.selected
        border: 1px solid $grey-300
        background: $grey-100
        box-sizing: border-box
        span
            border-bottom: 2px solid $matik-green
    &:focus:not(:active)
        box-shadow: none
    &:hover
        border: 1px solid $grey-400

.image-responsiveness-picker, .chart-responsiveness-picker
    color: $matik-black
    font-size: 14px
    font-weight: 400
    width: 60px
    height: 34px
    padding: 0 8px
    display: flex
    align-items: center
    border: 1px solid $grey-300
    background: $matik-white
    span
        padding: 5px 0
    &.on
        border-radius: 4px 0px 0px 4px
    &.off
        border-radius: 0px 4px 4px 0px
        border-left: none
    &.selected
        border: 1px solid $grey-300
        background: $grey-100
        box-sizing: border-box
        span
            border-bottom: 2px solid $matik-green
    &:focus:not(:active)
        box-shadow: none
    &:hover
        border: 1px solid $grey-400

.sidebar-description
    color: $grey-600
    font-size: 12px
    font-weight: 300
    padding-top: 0.5rem

.center-children 
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%

.html-builder-settings-icons
    display: flex
    justify-content: center
    align-items: center
    gap: 8px

.border-icons
    display: flex
    justify-content: center
    align-items: center
    gap: 0px

.html-builder-settings-button
    width: 32px
    height: 28px
    padding: 5px 7px 5px 7px
    border: 1px solid transparent
    border-radius: $border-radius-small
    &.selected
        border: 1px solid $blue-300
        background: $blue-100
    &:hover:not(.selected)
        border: 1px solid $grey-300
        background: $grey-100
    &.active
        border: 1px solid $blue-400
        background: $blue-200

.border-button-container
    width: 36px
    height: 36px
    padding: 2px
    background-color: transparent
    box-sizing: border-box
    display: flex
    justify-content: center
    align-items: center

.border-button
    display: flex
    justify-content: center
    width: 32px
    height: 28px
    padding: 5px 6px 5px 6px
    border: 1px solid transparent
    box-sizing: border-box
    border-radius: $border-radius-small
    cursor: pointer
    &.selected
        border: 1px solid $blue-300
        background: $blue-100
    &:hover:not(.selected):not(.disabled)
        border: 1px solid $grey-300
        background: $grey-100
    &.active
        border: 1px solid $blue-400
        background: $blue-200
    &.disabled
        color: $grey-400
        cursor: not-allowed

.html-builder-dropdown-select
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    width: 100%
    padding-left: 0.6rem
    margin-right: auto
    cursor: pointer

.html-builder-dropdown-image
    background: $matik-white
    min-width: 28px
    min-height: 28px
    border: 1px solid $grey-300
    border-radius: 6px
    padding: 4px
    &.data-source-icon
        padding: 4px
    &.no-data-source
        background: none
        border: none

.html-builder-dropdown-icon-group
    display: flex
    flex-direction: row
    align-items: center
    gap: 4px

.html-builder-dropdown-start-group
    display: flex
    flex-direction: row
    align-items: center
    gap: 10px

.html-builder-dropdown-end-group
    display: flex
    flex-direction: row
    align-items: center
    gap: 16px
    margin-right: 16px

.dc-font
    font-size: 14px
    font-weight: 400
    color: $matik-black
    &.italic
        font-style: italic
        color: $grey-600
    &.placeholder
        color: $grey-600
    &.disabled
        color: $grey-500
    &.disabled-italic
        font-style: italic
        color: $grey-400

.image-empty-state-font
    font-weight: 300
    &.bold
        font-weight: 600

.indicator-button
    display: flex
    justify-content: center
    align-items: center
    background: $matik-white
    color: $matik-black
    padding: 7px
    border: 1px solid $grey-300
    border-radius: 100px
    box-shadow: 0px 3px 6px 0px rgba(0, 31, 53, 0.06)
    z-index: 50
    cursor: pointer
    &.move-button
        cursor: move
    &:not(.disabled):not(.loading):hover
        border: 1px solid $blue-500
        background: $blue-100
        color: $blue-600
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.13)
    &:active:not(.disabled)
        border: 1px solid $grey-400
        background: $grey-100
        color: $grey-600
        box-shadow: 0px 3px 6px 0px rgba(0, 31, 53, 0.06)
    &.loading
        background: $matik-white
        border: 1px solid $grey-300
        border-radius: 100px
        box-shadow: 0px 3px 6px 0px rgba(0, 31, 53, 0.06)
    &.disabled
        cursor: not-allowed
        background: 1px solid $grey-100
        border: 1px solid $grey-300
        color: $grey-400
        border-radius: 100px
        box-shadow: 0px 3px 6px 0px rgba(0, 31, 53, 0.06)
        
button.icon-button
    background-color: transparent
    border: 0
    cursor: pointer
    color: $matik-black
    &:hover
        opacity: 0.5
    &:active
        opacity: 0.8
    &:disabled
        svg,path,rect
        stroke: $grey-400

.html-builder-save-btn
    float: right

.dropdown-item#export > div > svg > path
    fill: $matik-black

span.Collapsible__trigger .collapsible-chevron
    display: inline-block
    float: right

.html-builder-toggle
    user-select: none
