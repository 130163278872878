
.input-like
  width: auto
  height: 2.4rem
  padding-top: 0.5rem
  flex: 1
  border-radius: $input-radius
  border: 1px solid $input-border-color
  background-color: $input-background-color

.input-like:focus-within
  border-color: $matik-green
  box-shadow: 0 0 0 0.125em rgba($matik-green, 0.25)