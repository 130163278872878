// Feature Table

  .feature-table
  width: 100%
  font-size: $font-size-l

.feature-table-row
  display: flex
  width: 100%
  padding-bottom: 1rem
  padding-top: 1rem
  padding-left: 1.5rem
  flex: 1

  &:nth-child(even)
    background: $matik-white

.feature-table-cell
  color: $matik-black
  text-overflow: ellipsis
  text-wrap: nowrap
  overflow: hidden
  display: flex
  align-items: center
  width: 100%
  padding: 7px 5px

// Full Page Table

.full-page-table
  font-size: $font-size-l
  color: $grey-700

  &.isScrollable
    display: flex
    flex-direction: column
    min-height: 0
    height: 100%
    overflow-y: auto
    border-top: 1px solid $grey-300

.full-page-table-thead
  position: sticky
  top: 0
  left: 0
  background: $grey-100
  z-index: 3

.full-page-table-row-header
  display: flex
  width: 100%
  padding-left: 1.5rem
  padding-bottom: 0.5rem
  padding-top: 0.5rem
  flex: 1
  border-bottom: 1px solid $grey-300

  &.smallerFont
    font-size: $font-size-m

.full-page-table-row-body
  display: flex
  width: 100%
  padding-left: 1.5rem
  padding-bottom: 0.5rem
  padding-top: 0.5rem
  flex: 1
  overflow: visible

  &.smallerFont
    font-size: $font-size-m

  &:not(:first-child)
    border-top: 1px solid $grey-300

  &.show-top-border
    border-top: 1px solid $grey-300

  &:hover
    background: $matik-white
    cursor: pointer

.full-page-table-cell
  text-overflow: ellipsis
  text-wrap: nowrap
  display: flex
  align-items: center
  width: 100%
  padding: 7px 5px
  overflow: hidden

  &:hover
    cursor: pointer

  .more-dots
    font-size: 16pt

// Dynamic Content Full Page Table

.dc-full-page-table
  font-size: $font-size-m
  color: $matik-black

.dc-full-page-table-row-header
  display: flex
  width: 100%
  flex: 1
  border-bottom: 1px solid $grey-300

.dc-full-page-table-row-body
  display: flex
  width: 100%
  flex: 1
  overflow: visible

  &:hover
    background: $matik-white
    cursor: pointer
    > .dc-full-page-table-cell
      background: $matik-white

.dc-full-page-table-cell
  text-overflow: ellipsis
  text-wrap: nowrap
  display: flex
  align-items: center
  width: 100%
  padding: 7px 16px
  overflow: hidden
  background: $grey-100

  &.header
    color: $grey-600
    font-weight: $font-weight-regular

  &:nth-child(2), &:nth-child(3)
    padding-right: 0

  &:hover
    cursor: pointer

// Others

.checkbox-container
  flex: 32 0 auto
  width: 32px

.dynamic-content-sidebar
  display: flex
  flex-direction: column
  height: 100%

.cell-fade-out
  position: absolute
  top: 0
  right: 0
  bottom: 0
  width: 4rem
  pointer-events: none

  &::after
    content: ''
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: linear-gradient(to right, rgba($grey-100, 0), rgba($grey-100, 0.9), rgba($grey-100, 1))

  .dc-full-page-table-row-body:hover &::after
    background: linear-gradient(to right, rgba($matik-white, 0), rgba($matik-white, 0.9), rgba($matik-white, 1))