.template-card
  border: 1px solid $grey-300
  border-radius: 4px
  width: 100%
  height: 100%
  box-shadow: none

  .no-access-template-card-thumbnail-container
    background-color: $grey-100
    display: flex
    justify-content: center
    align-items: center

  .template-card-thumbnail-container
    background-color: $grey-300
    display: grid
    position: relative
    & > *
      grid-area: 1/-1

  .template-card-content
    padding: 1em
    border-top: 1px solid $grey-300
    width: 100%

    .primary-card-text
      color: $matik-black
      font-style: normal
      font-weight: 400
      font-size: 16px
      line-height: 24px
      margin-bottom: 4px

    .secondary-card-content
      margin-bottom: 0.5rem
      margin-left: 2px
      display: flex
      justify-content: left
      align-content: center

      .secondary-card-text
        color: $grey-700
        font-style: normal
        font-weight: normal
        font-size: 14px
        margin-left: 9px
        margin-right: 8px

    .source-type-img
      display: flex
      margin-top: 2px
      margin-right: 6px

  .card-multi-avatar
    height: 46px
    margin-left: 18px
    position: absolute
    left: 0.5rem
    right: 0.75rem
    bottom: 0.5rem

    .UserAvatar
      border-color: $matik-black
    .button
      height: 32px
      background-color: $grey-700
      color: #fff

#template-card-icon-box
  width: 32px

.template-type-icon-bg
  border-radius: 100px
  width: 32px
  height: 32px
  display: flex
  justify-content: center
  align-items: center
  color: $matik-white
  svg
    fill: $matik-white
.template-type-icon-bg.presentation
  background-color: $green-500
.template-type-icon-bg.email
  background-color: $purple-500
.template-type-icon-bg.document
  background-color: $blue-500
.template-type-icon-bg.spreadsheet
  background-color: $green-600

.template-card-right
  padding: 0px 16px
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%

.template-card-name
  display: flex
  justify-content: space-between
  width: 100%

.template-card-details
  display: flex
  justify-content: flex-start
  width: 100%
  span
    text-decoration: none
    color: $grey-600
    font-size: .75rem
  .tertiary-card-text
    font-size: 12px
    color: $grey-600
.template-card-ellipsis
  margin-top: 24px

.email-attachment-card
  box-sizing: border-box
  display: flex
  flex-direction: row
  padding: 24px
  gap: 10px
  width: 100%
  height: 184px
  left: 32px
  top: 477px
  background: #FFFFFF
  border: 1px solid $grey-300
  border-radius: 4px
.attachment-close
  width: 100%
  display: flex
  justify-content: flex-end
.attachment-card-right
  padding: 16px 16px
  display: flex
  flex-direction: row
  align-items: flex-start
  width: 60%
  .template-type-icon-bg
    margin: 12px 12px
    min-width: 32px
    min-height: 32px
  .attachment-card-text
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    .attachment-card-title
      margin: 0
      line-height: 24px
      width: 100%
    .attachment-card-details
      display: flex
      justify-content: flex-start
      align-items: center
      width: fit-content
      line-height: 18px
      img
        vertical-align: middle
        margin-right: 10px
      span
        text-decoration: none
        color: $grey-600
        font-size: .75rem
        width: fit-content
        margin-right: 10px
        white-space: nowrap
      .tertiary-card-text
        font-size: 12px
        color: $grey-600
.archived-button
  display: none
  width: fit-content
  height: 28px
  padding: 2px 8px 2px 8px
  border-radius: $border-radius-small
  margin: 4px
  margin-left: auto
  background: $matik-white
  border: 1px solid $grey-300
  box-shadow: $box-shadow-small
  font-size: $font-size-m
  font-weight: $label-weight
.is-archived
  .template-type-icon-bg.presentation,
  .template-type-icon-bg.email,
  .template-type-icon-bg.spreadsheet,
  .template-type-icon-bg.document
    background-color: $grey-400
.is-archived, .is-sub-template
  .description-overlay
    opacity: 50%
    &:hover,
    &:active,
    &:focus
      opacity: 100%
  .archived-button
    display: inline-flex
    position: initial
    align-self: flex-start
    justify-self: flex-end
    z-index: 6
    &:hover,
    &:active,
    &:focus
      & + .description-overlay .description
        display: block
.template-card-text
  justify-content: start
  .template-card-right
    width: auto
    overflow: hidden
    flex-grow: 1
span.dropdown-item
  padding-right: 3rem
  text-align: inherit
  white-space: nowrap
  width: 100%
