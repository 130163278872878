.preview-box
  background: $grey-100
  border: 1px solid $grey-300
  box-sizing: border-box
  border-radius: 4px
  margin-top: 1rem
  margin-bottom: 1rem
  padding: 16px
  .preview-box-header
    line-height: 140%
    color: $matik-black
    margin-bottom: .25rem
  .preview-box-body
    line-height: 140%
    color: $grey-600
