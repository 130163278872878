.account-inactive-content
  display: grid
  margin-top: -4rem
  padding-bottom: 3rem
  color: $grey-700
  font-size: 18px
  line-height: 24px

.pick-plan-button
  display: grid
  place-items: center
  width: 100%

.contact-link
  display: grid
  place-items: center
  color: $grey-700
  text-decoration: underline
  padding-top: 0.75rem

.logout-link
  display: grid
  place-items: center
  color: $grey-600
  padding-top: 0.75rem
